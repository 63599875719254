/*eslint-disable */
// import LikeModel from '../../Model/Like'
import request from '../../Utils/curl'

let Likes = {
  /**
   * likeList
   */
  async likeList (context) {
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "like_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at likeList() and Exception:',err.message)
    }
  },

  /**
   * likeView
   */
  async likeView (context, likeId) {
    try {
      
      let post_data = new FormData();
      post_data.append('like_id', likeId);
      return await request.curl(context, "like_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at likeList() and Exception:",err.message)
    }
    
  },

  /**
   * likeAdd
   */
  async likeAdd (context, likeObj) {
    try{
    let post_data = new FormData();
    
    for (let key in likeObj) {
      post_data.append(key, likeObj[key]);
    }

    return await request.curl(context, "like_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at likeAdd() and Exception:',err.message)
    }
  },

  /**
   * likeEdit
   */
  async likeEdit (context, likeObj) {
    try{
    let post_data = new FormData();
    
    for (let key in likeObj) {
      post_data.append(key, likeObj[key]);
    }

    return await request.curl(context, "like_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at likeEdit() and Exception:',err.message)
    }
  },

  /**
   * likeDelete
   */
  async likeDelete (context, likeId) {
    try{
    let post_data = new FormData();
    
    post_data.append('like_id', likeId);

    return await request.curl(context, "like_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at likeDelete() and Exception:',err.message)
    }
  }
}

export {
  Likes
}
