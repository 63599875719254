<template>
  <div>
    <ul class="mt-2">
      <li class="mb-2 ml-2" v-for="(postCommentReply) in Object.values(postCommentReplyObj)" :key="postCommentReply.comment_id">
        <div class="d-flex w-full">
          <div class="user-img pointer" v-on:click="viewProfile(postCommentReply)">
            <img v-if="postCommentReply.user_image" :src="postCommentReply.user_image" style="object-fit: cover;" class="avatar-35"  rounded="circle" fluid />
            <img v-else-if="postCommentReply.org?.org_profile_img" :src="postCommentReply.org.org_profile_img" alt="" class="avatar-35" rounded="circle" fluid />
            <img v-else-if="postCommentReply['user_image']" :src="postCommentReply['user_image']" class="avatar-35" rounded="circle" fluid/>
            <b-avatar v-else
              :variant="getBgColorForLetter(postCommentReply.org?.org_name ? postCommentReply.org.org_name : postCommentReply.user_name)"
              :text="getFirstLetterOfAString(postCommentReply.org?.org_name ? postCommentReply.org.org_name : postCommentReply.user_name)"
              class="avatar-35" rounded="circle" fluid>
            </b-avatar>
          </div>

          <div class="comment-data-block ml-3">
            <div class="d-flex flex-row  justify-content-start">
              <div>
                <div v-if="postCommentReply.comment_edited">
                  <b-form class="comment-text d-flex align-items-center mt-1" action="javascript:void(0);" style="background:#f4f4f4;">
                    <vue-editor
                      v-if='!(userData.user_role == "USERROLE11111" || userData.user_role == "USERROLE11118")'
                      v-model="vmCommentReply"
                      placeholder="Write a reply to comment ..."
                      :editorToolbar="customToolbar"
                      :autofocus="true"
                      @keyup.enter="commentReplyEdit(propPostComment)"
                    ></vue-editor>
                    <vue-editor
                      v-else
                      v-model="vmCommentReply"
                      placeholder="Write a reply to comment ..."
                      :editorToolbar="customToolbarAdmin"
                      :autofocus="true"
                      @keyup.enter="commentReplyEdit(propPostComment)"
                    ></vue-editor>
                    <div class="comment-attagement d-flex">
                      <b-link href="javascript:void(0);">
                        <i v-b-tooltip.hover.bottomright title="Cancel" class="far fa-times-circle mr-2" @click="commentReplyEditHideInput(postCommentReply)"></i>
                      </b-link>
                      <b-link href="javascript:void(0);">
                        <i v-b-tooltip.hover.bottomright title="Ok" class="fas fa-check mr-1" @click="commentReplyEdit(postCommentReply)"></i>
                      </b-link>
                    </div>
                  </b-form>
                </div>
                <div v-else class="d-flex justify-content-start" >
                  <div class="d-flex flex-column py-1 px-2 rounded pointer commentBox">
                    <h6 v-on:click="viewProfile(postCommentReply)">
                      {{postCommentReply.org?.org_name ? postCommentReply.org.org_name : postCommentReply.user_name}}
                    </h6>
                    <p class="mb-0">
                      <span v-html="postCommentReply.comment"></span>
                    </p>
                  </div>
                  <div v-if="postCommentReply.user_id === userData.user_id || userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" class="d-flex flex-col  justify-content-center align-items-center ml-3">
                    <b-dropdown  no-caret no-flip  size="sm" variant="light"  style="bacground: #fff !important;" >
                      <template #button-content>
                        <i class="fas fa-ellipsis-h" style="color:#afafaf;"></i>
                      </template>
                      <b-dropdown-item-button @click="commentReplyEditShowInput(postCommentReply)" >
                        Edit
                      </b-dropdown-item-button>
                      <b-dropdown-item-button @click="commentReplyDelete(postCommentReply.comment_id)" >
                        Delete
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </div>
                </div>

                <!-- like and time ago block -->
                <div class="d-flex flex-wrap align-items-center comment-activity" :key="updatedCommentList">
                  <b-link @click="commentReplyLike(postCommentReply.comment_id, 'COMMENT', postCommentReply.comment_reply_likedby_curr_user)">
                    <span v-if="postCommentReply.comment_reply_likedby_curr_user" class="text-secondary">Unlike</span>
                    <span v-else class="text-primary">Like</span>
                  </b-link>
                  <b-link @click="ugcx_add(postCommentReply.comment_id, 'COMMENT')">
                    <span class="text-primary">Hide</span>
                  </b-link>
                  <b-link @click="showModelReportFn(postCommentReply.comment_id, 'COMMENT')">
                    <span class="text-primary">Report</span>
                  </b-link>
                  <span>{{getFormatttedTimeAgo(postCommentReply.created_on)}}</span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div v-if="propPostComment.comment_reply ||  postCommentReplyObj.length < 0" class="d-flex mt-1 ml-5 overflow-hidden">
      <div class="user-img">
        <img v-if="userData.user_role == 'USERROLE11111'" :src="gidelogo" alt="" class="avatar-35" rounded="circle" fluid />
        <img v-else-if="getUserProfilePic()" :src="getUserProfilePic()" class="avatar-35" rounded="circle" fluid/>
        <b-avatar v-else :variant="getBgColorForLetter(userData.user_name)" :text="getFirstLetterOfAString(userData.user_name)" class="avatar-35" rounded="circle" fluid>
        </b-avatar>
      </div>
      <b-form class="comment-text d-flex align-items-center ml-3 mb-3 overflow-hidden " style="background:#f4f4f4;" action="javascript:void(0);">
        <vue-editor
          v-if='!(userData.user_role == "USERROLE11111" || userData.user_role == "USERROLE11118")'
          v-model="vmCommentReplyFormData.comment"
          placeholder="Write a reply to comment ..."
          :editorToolbar="customToolbar"
          :autofocus="true"
          @keyup.enter="commentReplyAdd(propPostComment)"
        />
        <vue-editor
          v-else
          v-model="vmCommentReplyFormData.comment"
          placeholder="Write a reply to comment ..."
          :editorToolbar="customToolbarAdmin"
          :autofocus="true"
          @keyup.enter="commentReplyAdd(propPostComment)"
        />
        <div class="comment-attagement d-flex">
          <b-link href="javascript:void(0);" v-b-tooltip.hover.bottomright title="Reply">
            <i class="ri-send-plane-line mr-2" @click="commentReplyAdd(propPostComment)"></i>
          </b-link>
        </div>
      </b-form>
    </div>

    <b-modal v-if="(reportModuleObjId && reportModuleName)"  v-model="showModelReport" scrollable size="md" centered title="Report " hide-footer  no-close-on-backdrop no-close-on-esc  >
      <ReportIssue  @emitCloseadminReportIssueAddModal="closeReportAddModal" :propModuleId="reportModuleObjId" :propModuleType="reportModuleName" :propOpenedInModal="true">
      </ReportIssue>
    </b-modal>
  </div>
</template>

<script>
import { Comments } from "../../../FackApi/api/comment.js"
import { Likes } from "../../../FackApi/api/like.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import Utility from "../../../Utils/utility.js"
import { UserContentExcludes } from "../../../FackApi/api/UserContentExclude.js"
import ReportIssue from "../../../components/ReportIssue.vue"
import { VueEditor } from "vue2-editor"

export default {
  name: "PostThread",
  components: {
    ReportIssue,
    VueEditor
  },
  props: {
    propPostComment: {
      type: Object
    },
    propPostList: {
      type: Object
    }
  },
  data () {
    return {
      postCommentReplyObj: {},
      vmCommentReply: "",
      vmCommentReplyFormData: Object.assign({}, this.initFormData),
      vmLikeFormData: Object.assign({}, this.initLikeFormData()),
      gidelogo: process.env.VUE_APP_GIDE_ADMIN_USER_IMAGE,
      updatedCommentList: 1,
      showModelReport: false,
      reportModuleObjId: null,
      reportModuleName: "COMMENT",
      customToolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        ["clean"] // remove formatting button
      ],
      customToolbarAdmin: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video"],
        ["clean"] // remove formatting button
      ]
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
  },
  watch: {
    propPostComment: {
      handler (newValue, oldValue) {
        if (!oldValue.comment_reply && newValue.comment_reply) {
          this.commentReplyList(this.propPostComment.comment_id)
        }
      },
      deep: true
    }
  },
  methods: {
    /**
     * View Follower On click
     */
    viewProfile (comment) {
      let url = `/user/${comment.user_id}`
      if (comment.org_id) {
        url = `/organisation/${comment.org_id}`
      }

      this.$router.push(url)
    },
    /**
     * initFormData
     */
    initFormData () {
      return {
        "comment": "",
        "comment_parent_id": ""
      }
    },
    /**
     * initLikeFormData
     */
    initLikeFormData () {
      return {
        "module_id": "",
        "module_name": "",
        "like_status": 0
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCommentFormData) {
          if (!this.vmCommentFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * getFormatttedTimeAgo()
     */
    getFormatttedTimeAgo (dateTime) {
      return Utility.getFormatttedTimeAgo(dateTime)
    },
    /**
     * getBgColorForLetter
     */
    getBgColorForLetter (name) {
      let firstLetter = this.getFirstLetterOfAString(name)
      return Utility.getBgColorForLetter(this, firstLetter)
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * getUserProfilePic
     */
    getUserProfilePic () {
      if (this.userData && this.userData.user_image && Object.keys(this.userData).length > 0) {
        return this.userData.user_image.profile_pic && this.userData.user_image.profile_pic.image_thumbnail
      }
      else {
        return false
      }
    },
    /**
     * commentReplyList
     */
    async commentReplyList (commentId) {
      try {
        let commentReplyListResp = await Comments.commentReplyList(this, commentId)
        if (commentReplyListResp && commentReplyListResp.resp_status) {
          this.postCommentReplyObj = commentReplyListResp.resp_data.comment_reply
        }
        else {
          this.postCommentReplyObj = {}
        }
      }
      catch (err) {
        console.error("Exception occurred at commentReplyList() and Exception:", err.message)
      }
    },
    /**
     * commentReplyAdd ()
     */
    async commentReplyAdd (parentCommentObj) {
      try {
        // Format the comment reply data
        this.vmCommentReplyFormData.comment = this.vmCommentReplyFormData.comment.replace(/\n\r?/g, "<br>")

        this.vmCommentReplyFormData.comment = this.vmCommentReplyFormData.comment.trim()
        let matches = this.vmCommentReplyFormData.comment.match(/\bhttps?:\/\/\S+/gi)

        if (matches && matches.length > 0) {
          for (let i in matches) {
            let url = matches[i]
            this.vmCommentReplyFormData.comment = this.vmCommentReplyFormData.comment.replace(url, `<a href='${url}' target='_blank'>${url}</a>`)
          }
        }

        this.vmCommentReplyFormData.module_id = parentCommentObj.module_id
        this.vmCommentReplyFormData.module_name = "POST"
        this.vmCommentReplyFormData.comment_parent_id = parentCommentObj.comment_id
        let commentReplyAddResp = await Comments.commentAdd(this, this.vmCommentReplyFormData)

        if (commentReplyAddResp && !commentReplyAddResp.resp_status) {
          await ApiResponse.responseMessageDisplay(this, commentReplyAddResp)
          return false
        }
        else {
          let commentReplyAddData = {}
          let commentId = commentReplyAddResp.resp_data.data.comment_id
          commentReplyAddData[commentId] = { ...commentReplyAddResp.resp_data.data }
          commentReplyAddData[commentId].comment_edited = false
          commentReplyAddData[commentId].comment_reply = false
          commentReplyAddData[commentId].comment_show_more = false

          if (this.userData.user_role === "USERROLE11111") {
            commentReplyAddData[commentId].user_id = "1"
            commentReplyAddData[commentId].user_admin = 1
            commentReplyAddData[commentId].user_name = "Gide"
            commentReplyAddData[commentId].user_image = process.env.VUE_APP_GIDE_ADMIN_USER_IMAGE
          }
          else if (this.userData.user_role === "USERROLE11118") {
            commentReplyAddData[commentId].user_id = "1"
            commentReplyAddData[commentId].user_admin = 1
            commentReplyAddData[commentId].user_name = "Gide Counsellor"
            commentReplyAddData[commentId].user_image = process.env.VUE_APP_GIDE_ADMIN_USER_IMAGE
          }
          else if (this.userData.user_role === "USERROLE11115") {
            // Org
            let orgIds = Object.keys(this.userData.modules.organisations)
            commentReplyAddData[commentId].org = this.userData.modules.organisations[orgIds[0]]
          }
          else if (this.userData.user_role === "USERROLE11116") {
            // Org
            let orgIds = Object.keys(this.userData.modules.organisations)
            commentReplyAddData[commentId].org = this.userData.modules.organisations[orgIds[0]]
          }
          else if (this.userData.user_role === "USERROLE11117") {
            // Org
            let orgIds = Object.keys(this.userData.modules.organisations)
            commentReplyAddData[commentId].org = this.userData.modules.organisations[orgIds[0]]
          }
          else {
            commentReplyAddData[commentId].user_id = this.userData.user_id
            commentReplyAddData[commentId].user_name = this.userData.user_name
            commentReplyAddData[commentId].user_image = this.userData?.user_image?.profile_pic?.image_thumbnail ? this.userData.user_image.profile_pic.image_thumbnail : null
          }

          // make new  comment obj
          this.postCommentReplyObj = Object.assign({}, { ...this.postCommentReplyObj, ...commentReplyAddData })
          this.$emit("emitUpdatePostComment", { addCommentCount: true })
        }

        this.vmCommentReplyFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at commentReplyAdd() and Exception", err.message)
      }
    },
    /**
     * commentReplyDelete
     */
    async commentReplyDelete (commentId) {
      try {
        let commentReplyDeleteResp = await Comments.commentDelete(this, commentId)
        await ApiResponse.responseMessageDisplay(this, commentReplyDeleteResp)
        if (commentReplyDeleteResp && !commentReplyDeleteResp) {
          return false
        }
        else {
          this.$emit("emitUpdatePostComment", { decreaseCommentCount: true })
          this.$delete(this.postCommentReplyObj, commentId)
        }
      }
      catch (err) {
        console.error("Exception occurred at commentReplyDelete() and Exception:", err.message)
      }
    },
    /**
     * commentReplyEdit()
    */
    async commentReplyEdit (postCommentReplyObj) {
      try {
        let editedCommentData = { ...postCommentReplyObj, comment: this.vmCommentReply }
        let commentReplyEditResp = await Comments.commentEdit(this, editedCommentData)
        await ApiResponse.responseMessageDisplay(this, commentReplyEditResp)
        if (commentReplyEditResp && !commentReplyEditResp) {
          this.commentReplyEditHideInput(postCommentReplyObj)
          this.vmCommentReply = ""
          return false
        }
        else {
          let commentId = postCommentReplyObj.comment_id
          this.postCommentReplyObj[commentId] = { ...editedCommentData, comment_edited: false }
        }
      }
      catch (err) {
        console.error("Exception occurred at commentReplyEdit () and Exception:", err.message)
      }
    },
    /**
     * commentReplyLike
    */
    async commentReplyLike (moduleId, moduleName, likeStatus) {
      try {
        this.vmLikeFormData.module_id = moduleId
        this.vmLikeFormData.module_name = moduleName
        this.vmLikeFormData.like_status = likeStatus ? 0 : 1
        let likeAddResp = await Likes.likeAdd(this, this.vmLikeFormData)
        if (likeAddResp && !likeAddResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, likeAddResp)
        }
        else {
          let likeUnlikeData
          if (this.vmLikeFormData.like_status) {
            likeUnlikeData = { ...this.postCommentReplyObj[moduleId], comment_reply_likedby_curr_user: 1 }
          }
          else {
            likeUnlikeData = { ...this.postCommentReplyObj[moduleId], comment_reply_likedby_curr_user: 0 }
          }
          this.postCommentReplyObj[moduleId] = likeUnlikeData
        }
      }
      catch (err) {
        console.error("Exception occurred at commentReplyLike  () and Exception", err.message)
      }
    },
    /**
     * commentReplyHideInput()
     */
    commentReplyHideInput (postCommentReply) {
      try {
        let commentId = postCommentReply.comment_id
        this.postCommentReplyObj[commentId] = { ...postCommentReply, comment_reply: true }
      }
      catch (err) {
        console.error("Exception occurred at commentReplyHideInput() and Exception", err.message)
      }
    },
    /**
     * commentReplyEditShowInput()
     */
    commentReplyEditShowInput (postCommentReply) {
      try {
        this.vmCommentReply = postCommentReply.comment
        let commentId = postCommentReply.comment_id
        this.postCommentReplyObj[commentId] = { ...postCommentReply, comment_edited: true }
      }
      catch (err) {
        console.error("Exception occurred at commentReplyEditShowInput() and Exception", err.message)
      }
    },
    /**
     * commentReplyEditHideInput()
     */
    commentReplyEditHideInput (postCommentReply) {
      try {
        this.vmCommentReply = ""
        let commentId = postCommentReply.comment_id
        this.postCommentReplyObj[commentId] = { ...postCommentReply, comment_edited: false }
      }
      catch (err) {
        console.error("Exception occurred at commentReplyEditHideInput() and Exception", err.message)
      }
    },
    /**
     * showMoreCommentReply
     */
    showMoreCommentReply (postCommentReplyObj) {
      try {
        let commentId = postCommentReplyObj.comment_id

        if (postCommentReplyObj[commentId]) {
          this.postCommentReplyObj[commentId] = { ...postCommentReplyObj, comment_show_more: true }
        }
        else {
          this.postCommentReplyObj[commentId] = { ...postCommentReplyObj, comment_show_more: true }
        }
      }
      catch (err) {
        console.error("Exception occurred at showMoreComment() and Exception:", err.message)
      }
    },
    /**
     * ugcx_add
     */
    async ugcx_add (moduleObjId, moduleName) {
      try {
        let userContentExcludeObj = {}
        userContentExcludeObj.module_obj_id = moduleObjId
        userContentExcludeObj.module_name = moduleName

        let ugcxResp = await UserContentExcludes.userContentExcludeAdd(this, userContentExcludeObj)
        if (ugcxResp.resp_status) {
          if (moduleName === "COMMENT") {
            this.$delete(this.postCommentReplyObj, moduleObjId)
            this.updatedCommentList += 1
          }
        }
        else {
          ApiResponse.responseMessageDisplay(this, ugcxResp)
          return false
        }
      }
      catch (err) {
        console.error("Exception occurred at ugcx_add() and Exception:", err)
      }
    },
    /**
     * showModelReportFn
     */
    showModelReportFn (moduleObjId, moduleName) {
      this.reportModuleObjId = moduleObjId
      this.reportModuleName = moduleName
      this.showModelReport = true
    },
    /**
     * closeReportAddModal
     */
    closeReportAddModal () {
      this.showModelReport = false
    }
  }
}
</script>
<style lang="scss" scoped>
 .form-control textarea{
    height: auto !important;
  }
</style>
