/*eslint-disable */
import request from "../../Utils/curl";

let Videos = {
  /**
   * videoList
   */
  async videoList(context) {
    try {
      let post_data = new FormData();

      return await request.curl(context, "video_list", post_data).then(async (response) => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at videoList() and Exception:", err.message);
    }
  },
  /**
  * videoListCustomType
  */
  async videoListCustomType(context, videoObj) {
    try {
      let post_data = new FormData();
      for (const key in videoObj) {
        post_data.append(key, videoObj[key])
      }
      return await request.curl(context, "video_list_custom_module", post_data).then(async (response) => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at videoList() and Exception:", err.message);
    }
  },

  /**
   * videoView
   */
  async videoView(context, videoId) {
    try {
      let post_data = new FormData();
      post_data.append("video_id", videoId);
      return await request.curl(context, "video_view", post_data).then(async (response) => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at videoView() and Exception:", err.message);
    }
  },

  /**
   * videoAdd
   */
  async videoAdd(context, videoObj) {
    try {
      let post_data = new FormData();

      for (let key in videoObj) {
        post_data.append(key, videoObj[key]);
      }

      return await request.curl(context, "video_add", post_data).then(async (response) => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at videoAdd() and Exception:", err.message);
    }
  },

  /**
   * videoEdit
   */
  async videoEdit(context, videoObj) {
    try {
      let post_data = new FormData();

      for (let key in videoObj) {
        post_data.append(key, videoObj[key]);
      }

      return await request.curl(context, "video_edit", post_data).then(async (response) => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at videoEdit() and Exception:", err.message);
    }
  },

  /**
   * videoDelete
   */
  async videoDelete(context, videoId) {
    try {
      let post_data = new FormData();

      post_data.append("video_id", videoId);

      return await request.curl(context, "video_delete", post_data).then(async (response) => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at videoDelete() and Exception:", err.message);
    }
  },
    /**
   * videoDelete
   */
  async videoDeleteById(context, videoId) {
    try {
      let post_data = new FormData();

      post_data.append("video_id", videoId);

      return await request.curl(context, "video_delete_by_id", post_data).then(async (response) => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at videoDelete() and Exception:", err.message);
    }
  },
  /**
   * videoDeleteByID
   */
  async videoDeleteByID (context, video_id) {
    try{
    let post_data = new FormData();

    post_data.append('video_id', video_id);

    return await request.curl(context, "video_delete_by_id", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at imageDelete() and Exception:',err.message)
    }
  }
};

export { Videos };
