<template>
  <div :key="propModuleId">
    <div v-if="!propModuleId" :key="updatePostList">
      <div v-for="post in getPosts" :key="post.post_id+post.update" ref="post">
        <!-- Home Page -->
        <PostView :propPost="post" @emitDeletePost="deletePost"></PostView>
      </div>
    </div>
    <div v-else :key="updatePostListOther">
      <div v-for="post in postListObj" :key="post.post_id" ref="post">
        <!-- Club/Organisation Page Posts -->
        <PostView :propPostType="propPostType" :propModuleObjId="propModuleId" :propPost="post" @emitDeletePost="deletePost">
        </PostView>
      </div>
      <div v-if="allLoaded && Object.keys(postListObj).length < 1" class="text-center p-3">
        No Post available
      </div>
    </div>
    <b-col class="gide_spiner_center_align" lg="12">
      <div v-if="!allLoaded" v-infinite-scroll="postList" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
        <b-spinner variant="primary" label="Spinning" >
        </b-spinner>
      </div>
    </b-col>
  </div>
</template>
<style lang="scss" scoped>
</style>

<script>
import PostView from "./PostView.vue"
import { Posts } from "../../../FackApi/api/post"
import { PushTokenHandler } from "../../../FackApi/api/pushtoken"
import { EventBus } from "../../../main"
import { analyticsTracker } from "../../../FackApi/api/analytics/analytics_tracker"

export default {
  name: "PostList",
  components: {
    PostView
  },
  props: {
    propModuleId: {
      type: String
    },
    propPostType: {
      type: Number,
      default: null
    }
  },
  created () {
    let self = this
    EventBus.$on("emitPostAdd", event => {
      if (typeof event === "object") {
        self.postAdd(event)
      }
    })
  },
  data () {
    return {
      currentPage: 1,
      postListObj: {},
      commentCountObj: {},
      vmPostDetailModal: false,
      vmPostDetailPostId: "",
      cvPostDetailPagePostUrl: "",
      clubData: {},
      orgData: {},
      cvPostMsg: "",
      updatePostList: 1,
      updatePostListOther: 1,
      busy: false,
      allLoaded: false
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    },
    getPosts () {
      return this.$store.getters["Post/getPosts"]
    },
    postCommentCount () {
      return this.$store.getters["Post/getPostCommentCount"]
    },
    selectedUserInterests () {
      return this.$store.getters["User/getSelectedUserInterests"]
    },
    getPostNewImages () {
      return this.$store.getters["Post/getPostNewImages"]
    }
  },
  watch: {
    async propModuleId (newVal, oldVal) {
      if (this.propModuleId) {
        await this.resetPost()
        await this.postList(this.currentPage)
      }
    }
  },
  mounted () {
    this.postList()
    PushTokenHandler.fcm_push_token_get(this)
    analyticsTracker.analyticsTrackerAdd(this, { module_name: "home", action: "open" })
  },
  methods: {
    /**
     * postAdd
    */
    postAdd (post) {
      if (this.propModuleId) {
        const postId = Object.keys(post)[0]

        if (this.postListObj[postId]) {
          this.postListObj[postId] = post[postId]
        }
        else {
          this.postListObj = { ...post, ...this.postListObj }
        }
      }
    },
    /**
     * list all post
     */
    async postList () {
      try {
        if (this.allLoaded) {
          return
        }

        let filter = {}
        this.busy = true

        let postListResp = await Posts.postList(this, this.currentPage, filter, this.propModuleId)
        if (postListResp && postListResp.resp_status) {
          this.currentPage++
          if (postListResp.resp_data.club_data) {
            this.clubData = postListResp.resp_data.club_data
          }

          if (postListResp.resp_data.user_data) {
            this.userModuleData = postListResp.resp_data.user_data
          }

          let postListTempObj = {}
          for (let pIndex in postListResp.resp_data.post_data) {
            let post = postListResp.resp_data.post_data[pIndex]

            if (post.post_type === 1) {
              post.module_data = this.orgData[post.post_module_obj_id]
              post.module_obj_name = post.module_data?.org_name
              post.module_obj_image = post.user_image
              post.post_type_name = "org"
            }
            else if (post.post_type === 3) {
              post.module_data = this.clubData[post.post_module_obj_id]
              post.module_obj_name = post.user_name
              post.module_obj_image = post.user_image
              post.post_type_name = "club"
            }

            postListTempObj[post.post_id] = post
          }

          let postListData = {}
          if (!this.propModuleId) {
            postListData = { ...this.getPosts, ...postListTempObj }
          }
          else {
            postListData = postListResp.resp_data.post_data
          }

          if (this.getPostNewImages) {
          // when there is  recently add post image is present in store
          // to prevent the image loss of recently added  post image
            let postId = this.getPostNewImages.module_id
            let postImages = this.getPostNewImages.images
            if (postId && postImages && postListData[postId]) {
              postListData[postId].post_images = postImages
            }
          }

          let postCommentCountData = { ...this.postCommentCount, ...postListResp.resp_data.commemt_count_data }
          // add post list to the store
          if (this.propModuleId) {
            this.postListObj = { ...this.postListObj, ...postListData }
          }
          else {
            await this.$store.dispatch("Post/setPostsAction", postListData)
          }

          // add comment list of post to the store
          await this.$store.dispatch("Post/setPostCommentCountAction", postCommentCountData)
          this.cvPostMsg = ""
        }
        else {
          this.cvPostMsg = "No posts available."
          this.allLoaded = true
        }
      }
      catch (err) {
        console.error("Exception occurred at postList() and Exception:", err.message)
      }
      finally {
        this.busy = false
      }
    },
    /**
     * resetPost
     */
    async resetPost () {
      try {
        if (this.propModuleId) {
          this.currentPage = 1
        }
        else {
          this.currentPage = 0
        }

        this.allLoaded = false
        this.postListObj = null
        await this.$store.dispatch("Post/setPostsAction", null)
      }
      catch (err) {
        console.error("Exception ocuured at resetPost () and Exception:", err.message)
      }
    },
    /**
     * Post delete
     */
    deletePost (postId) {
      if (postId in this.getPosts) {
        delete this.getPosts[postId]
        this.updatePostList += 1
      }
      else if (postId in this.postListObj) {
        // Club & Org Post
        delete this.postListObj[postId]
        this.updatePostListOther += 1
      }
    }
  }
}
</script>

<style>
.hide-show-more{
  visibility: hidden;
  opacity: 0;
  transform: scale(0.2);
}
.unhide-show-more{
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
</style>
