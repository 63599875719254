/*eslint-disable */
import request from "../../../Utils/curl"
import Utility from "../../../Utils/utility";

let analyticsTracker = {
  /**
   * analytics_trackerList
   */
  async analytics_trackerList (context, filter  = {}) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";
    try {
      let post_data = new FormData()
      
      post_data.append('filter', JSON.stringify(filter));

      return await request.curl(context, "analytics_tracker_list", post_data)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at analytics_trackerList() and Exception:", err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },
   /**
   * analytics_trackerList
   */
   async analytics_tracker_totalopencount (context, filter  = {}) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";
    try {
      let post_data = new FormData()
      
      post_data.append('filter', JSON.stringify(filter));

      return await request.curl(context, "analytics_tracker_totalopencount", post_data)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at analytics_trackerList() and Exception:", err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },
   
  /**
   * analytics_trackerView
   */
  async analytics_trackerView (context, analyticsTrackerId) {
    try {
      let post_data = new FormData()
      post_data.append("usrl_id", analyticsTrackerId)
      return await request.curl(context, "analytics_tracker_view", post_data)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at analytics_trackerView() and Exception:", err.message)
    }
  },

  async analyticsTrackerAdd (context, analyticsTrackerObj) {
    try {
      let postData = new FormData()

      for (let key in analyticsTrackerObj) {
        if (analyticsTrackerObj[key]) {
          postData.append(key, analyticsTrackerObj[key])
        }
      }

      return await request.curl(context, "analytics_tracker_add", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at analyticsTrackerAdd() and Exception:", err.message)
    }
  },

  /**
   * analytics_trackerEdit
   */
  async analytics_trackerEdit (context, analytics_trackerObj) {
    try {
      let post_data = new FormData()

      for (let key in analytics_trackerObj) {
        post_data.append(key, analytics_trackerObj[key])
      }

      return await request.curl(context, "analytics_tracker_edit", post_data)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at analytics_trackerEdit() and Exception:", err.message)
    }
  },

  /**
   * analytics_trackerDelete
   */
  async analytics_trackerDelete (context, analyticsTrackerId) {
    try {
      let post_data = new FormData()

      post_data.append("usrl_id", analyticsTrackerId)

      return await request.curl(context, "analytics_tracker_delete", post_data)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.log("Exception occurred at analytics_trackerDelete() and Exception:", err.message)
    }
  },

  /**
  * analytics_tracker_module_name
  */
  async module_name_list (context) {
    try{
      let post_data = new FormData();

      return await request.curl(context, "analytics_tracker_module_name", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at countryList() and Exception:',err.message)
    }
  },

}

export {
  analyticsTracker
}
