/*eslint-disable */
// import CommentModel from '../../Model/Comment'
import request from '../../Utils/curl'

let Comments = {
  /**
   * commentList
   */
  async commentList (context, payload) {
    try{
      /** payload{ limit: 10, offset: 0, module_id} */
      let post_data = new FormData();
      for (let key in payload) {
        if (payload[key] || typeof payload[key] === 'number') {
          post_data.append(key, payload[key])
        }
      }
  
      return await request.curl(context, "comment_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at commentList() and Exception:',err.message)
    }
  },

  /**
   * commentView
   */
  async commentView (context, commentId) {
    try {
      
      let post_data = new FormData();
      post_data.append('comment_id', commentId);
      return await request.curl(context, "comment_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at commentList() and Exception:",err.message)
    }
    
  },

  /**
   * commentAdd
   */
  async commentAdd (context, commentObj) {
    try{
    let post_data = new FormData();
    
    
    for (let key in commentObj) {
      if (commentObj[key] || typeof commentObj[key] == 'number') {
        post_data.append(key, commentObj[key]);
      }
    }

    return await request.curl(context, "comment_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at commentAdd() and Exception:',err.message)
    }
  },

  /**
   * commentEdit
   */
  async commentEdit (context, commentObj) {
    try{
    let post_data = new FormData();
    
    for (let key in commentObj) {
      post_data.append(key, commentObj[key]);
    }

    return await request.curl(context, "comment_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at commentEdit() and Exception:',err.message)
    }
  },

  /**
   * commentDelete
   */
  async commentDelete (context, commentId) {
    try{
    let post_data = new FormData();
    
    post_data.append('comment_id', commentId);

    return await request.curl(context, "comment_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at commentDelete() and Exception:',err.message)
    }
  },
  /**
   * commentReplyList
   */
   async commentReplyList (context, paarentCommentId) {
    try{
      let post_data = new FormData();
      post_data.append('comment_parent_id', paarentCommentId )
  
      return await request.curl(context, "comment_reply_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at commentList() and Exception:',err.message)
    }
  }
}

export {
  Comments
}
