<template>
  <iq-card class="side-iq-card">
    <template v-slot:headerTitle>
      <h4 class="card-title">
        Universities
      </h4>
    </template>
    <template v-if="organisationListObj" v-slot:body>
      <hr class="mt-0"/>
      <ul class="media-story m-0 p-0 ListPage">
        <li v-for="(org,index) in organisationListObj" :key="index+Object.keys(organisationListObj).length" class="d-flex align-items-center mt-3 btn p-0" @click="goToUnivView(org.org_id)">
          <img v-if="org.org_profile_img" :src="org.org_profile_img" class="avatar-50 rounded-circle orgLogo" alt="organisation">
          <div v-else class="rounded-circle"  style="border: 2px solid rgba(0, 0, 0, 0.1);padding: 2px;">
            <b-avatar variant="primary" class="b-avatar rounded-circle avatar-50" :text="getFirstLetterOfAString(org.org_name)" >
            </b-avatar>
          </div>
          <div class="stories-data ml-2 d-flex flex-fill justify-content-between leftAlign" >
            <h5 class="text-left homeHeading">
              {{ org.org_name }}
            </h5>
            <button style="all:unset; font-weight: 500; cursor: pointer;" @click="followUniversity($event, org.org_id, org.followed_by_curr_user)">
              <span v-if="org.followed_by_curr_user === 0" class="text-primary">Follow</span>
              <span v-else class="text-secondary">Unfollow</span>
            </button>
          </div>
        </li>
      </ul>
      <b-btn class="d-block mt-3 border-light iq-bg-dark" style="width: 100%;" @click="goToUniversityList()">
        <i class="ri-add-line"></i> See All
      </b-btn>
    </template>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </iq-card>
</template>
<style lang="scss" scoped>
  .orgLogo {
    object-fit: contain;
    width: 100px;
    height: 100px;
    background: white;
  }
</style>
<script>
import { Organisations } from "../../../FackApi/api/organisation.js"
import { Follows } from "../../../FackApi/api/follow.js"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "UniversityHomeList",
  mounted () {
    this.loadRequiredData()
  },
  components: {
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  data () {
    return {
      organisationListObj: {},
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Organisation Follow Response",
      vmFollowData: Object.assign({}, this.initFollowData())
    }
  },
  methods: {
    /**
     * initFollowData
     */
    initFollowData () {
      return {
        module_id: "",
        module_name: "",
        follow_status: 0
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      this.organisationList()
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * goToUniversityList
     */
    goToUniversityList () {
      this.$router.push("/organisations/UNIV")
    },
    /**
     * goToUnivView
     */
    goToUnivView (orgId) {
      this.$router.push("/organisations/UNIV/" + orgId)
    },
    /**
     * userRoleAllowedToPostOrNot
     */
    userRoleAllowedToPostOrNot () {
      if (this.userData && this.userData.user_role) {
        return (this.userData.user_role === "USERROLE11111" || this.userData.user_role === "USERROLE11118")
      }
    },
    /*
   * organisationList
   * */
    async organisationList () {
      try {
        let filter = {
          limit: 3,
          type: ["UNIV"]
        }

        let organisationListResp = await Organisations.organisationList(this, filter)
        if (organisationListResp && organisationListResp.resp_status) {
          this.organisationListObj = organisationListResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationList() and Exception:", err.message)
      }
    },

    /**
     *  organisationFollow ()
     */
    async followUniversity (event, moduleId, followStatus) {
      event.stopPropagation()
      try {
        this.vmFollowData.module_id = moduleId
        this.vmFollowData.module_name = "UNIV"
        this.vmFollowData.follow_status = followStatus ? 0 : 1
        let followResp = await Follows.followAdd(this, this.vmFollowData)
        await ApiResponse.responseMessageDisplay(this, followResp)
        if (followResp && !followResp.resp_status) {
          return
        }
        else {
          this.organisationListObj[moduleId].followed_by_curr_user = this.vmFollowData.follow_status

          this.$set(this.organisationListObj, moduleId, this.organisationListObj[moduleId])
          this.vmFollowData = Object.assign({}, this.initFollowData())
        }
      }
      catch (err) {
        console.error("Exception occured at followUniversity() and Exception", err.message)
      }
    }
  }
}
</script>
