<template>
  <b-row>
    <b-col sm="12">
      <b-row class=" m-0 p-0">
        <b-col lg="8">
          <tab-content id="pills-tabContent-2">
            <tab-content-item :active="true" id="profile-feed" aria-labelled-by="pills-feed-tab">
              <PostAdd  v-if="showPostAdd()" />
              <PostList />
            </tab-content-item>
          </tab-content>
        </b-col>
        <b-col lg="4" class="gide_right_mobile_data" v-if="!isMobileDevice">
          <EventHomeList v-if="!sideBarItemsList.Activities.exludeUserRole.includes(userData.user_role)"/>
          <UniversityHomeList/>
          <CareerHomeList/>
          <ClubHomeList v-if="!sideBarItemsList.Clubs.exludeUserRole.includes(userData.user_role)"/>
        </b-col>
      </b-row>
    </b-col>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-row>
</template>
<style lang="scss" scoped>
  .side-iq-card .iq-card-body {
    padding-top: 0!important;
  }
</style>
<script>
import { socialvue } from "../../../config/pluginInit.js"
import SideBarMenu from "../../../Utils/sidebarMenu.js"
import PostAdd from "./PostAdd.vue"
import PostList from "./PostList.vue"
import ClubHomeList from "../ListPages/Club/ClubHomeList.vue"
import UniversityHomeList from "../Organisation/UniversityHomeList.vue"
import EventHomeList from "../Event/EventHomeList.vue"
import CareerHomeList from "../ListPages/Career/CareerHomeList.vue"
import { User } from "../../../FackApi/api/user.js"

export default {
  name: "SocialPostsApp",
  components: {
    PostAdd,
    PostList,
    ClubHomeList,
    UniversityHomeList,
    EventHomeList,
    CareerHomeList
  },
  data () {
    return {
      isMobileDevice: window.__IS__MOBILE_DEVICE__,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Post List Response",
      suggestionEvent: [],
      event: [],
      user: {},
      galary: [],
      action: [],
      timelineItems: [],
      friends: [],
      userBio: [],
      story: [],
      suggestions: [],
      news: [],
      twitterFeed: [],
      recentPages: [],
      clubListObj: [],
      userObj: {},
      mySubsciptionPlan: null,
      sideBarItemsList: SideBarMenu.getSideBarMenuObjList()
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    window.addEventListener("flutterInAppWebViewPlatformReady", function (event) {
      console.log("flutterInAppWebViewPlatformReady NOW")
    })
    this.loadRequiredData()
  },
  methods: {
    /**
     * showPostAdd
     */
    showPostAdd () {
      return true // Show this to everyone
      /**
      if (this.userData.user_role === "USERROLE11111" || this.userData.user_role.includes("USERROLE") || this.userData.user_gide_verified === 1) {
        return true
      }
      else if (this.userData.user_role === "USERROLE11114" && this.userData.user_gide_verified === 1) {
        return true
      }
      else {
        return false
      }
      */
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * loadRequiredData
     */
    async loadRequiredData () {
      socialvue.index()
    },
    /**
     * UserView
     */
    async userView () {
      try {
        let userViewResp = await User.userView(this, this.cvUserId, this.userData.user_id)
        if (userViewResp && userViewResp.resp_status) {
          this.userObj = userViewResp.resp_data.data
          this.userFollowObj = userViewResp.user_follow
          // if (userViewResp.user_follow && userViewResp.user_follow.follow_count) {
          //   this.socialInfo[1].value = userViewResp.user_follow.follow_count
          // }
        }
        else {
          return false
        }
      }
      catch (err) {
        console.error("Exception occurred at userView() and Exception:", err.message)
      }
    }
  }
}
</script>
