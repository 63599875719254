<template>
  <iq-card body-class="p-0" :key="post.post_id">
    <template v-slot:body>
      <div class="user-post-data p-3">
        <div class="d-flex flex-wrap" v-if="post">
          <div class="media-support-user-img  mr-3 pointer" @click="goToUserProfile(post)">
            <img v-if="post.user_image || (post.org && post.org.org_profile_img)" :src="getProfileImage(post)" class="avatar-60 logo-icon" />
            <b-avatar v-else
              :variant="getBgColorForLetter(post.org?.org_name ? post.org.org_name : post.user_name)"
              :text="getFirstLetterOfAString(post.org?.org_name ? post.org.org_name : post.user_name)" class="avatar-60">
            </b-avatar>
          </div>
          <div class="media-support-info ">
            <h5 class="mb-0">
              <b-link href="javascript:void(0)" class="mt-1 pointer" style="color:#333;" @click="goToUserProfile(post)">
                {{ getProfileName(post) }}
              </b-link>
            </h5>
            <p class="mb-0 text-secondary">
              {{getFormatttedTimeAgo(post.created_on)}}
            </p>
          </div>
          <div class="iq-card-header-toolbar d-flex align-items-center" style="padding:0">
            <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0 mr-0">
              <template v-slot:button-content>
                <b-link href="javascript:void(0)" class="text-secondary postThreeDotIcon">
                  <i class="ml-0 ri-more-2-line"></i>
                </b-link>
              </template>
              <a v-if="(userData.user_id === post.user_id) || (userData.user_role === 'USERROLE11111')" class="dropdown-item p-3" @click="openEditPostModal()" href="javascript:void(0)">
                <div class="d-flex align-items-top">
                  <div class="icon font-size-20"><i class="fa-solid fa-pen-to-square"></i></div>
                  <div class="data ml-2">
                    <h6>Edit Post</h6>
                  </div>
                </div>
              </a>
              <a class="dropdown-item p-3" @click="goToPostDetail(post.post_id)" href="javascript:void(0)">
                <div class="d-flex align-items-top">
                  <div class="icon font-size-20"><i class="fa-solid fa-eye"></i></div>
                  <div class="data ml-2">
                    <h6>View Post</h6>
                  </div>
                </div>
              </a>
              <a class="dropdown-item p-3" @click="ugcx_add(post.post_id, 'POST')" href="javascript:void(0)">
                <div class="d-flex align-items-top">
                  <div class="icon font-size-20"><i class="fa-solid fa-eye-slash"></i></div>
                  <div class="data ml-2">
                    <h6>Hide Post</h6>
                  </div>
                </div>
              </a>
              <a class="dropdown-item p-3" @click="showModelReportFn(post.post_id, 'POST')" href="javascript:void(0)">
                <div class="d-flex align-items-top">
                  <div class="icon font-size-20"><i class="fa-solid fa-circle-exclamation"></i></div>
                  <div class="data ml-2">
                    <h6>Report Post</h6>
                  </div>
                </div>
              </a>
              <a class="dropdown-item p-3" v-if="userData.user_role === 'USERROLE11111' || userData.user_id == post.user_id" @click="showAlertForDelete(post.post_id)" href="javascript:void(0)">
                <div class="d-flex align-items-top">
                  <div class="icon font-size-20"><i class="fa-solid fa-trash"></i></div>
                  <div class="data ml-2">
                    <h6>Delete Post</h6>
                  </div>
                </div>
              </a>
            </b-dropdown>
          </div>
        </div>
      </div>
    </template>
    <hr class="m-0" />
    <div class="user-post px-3 obverflow-hidden">
      <p :class="`my-2 line-wrap track${propPost.post_id}`" v-if="post && post.post_desc && post.post_desc!=='null'" >
        <span v-html="post.post_desc"></span>
      </p>
      <p v-if="post.post_desc && post.post_desc.length > 256" class="pointer float-right" style="color: #e5252c;" @click="goToPostDetail(post.post_id)">&nbsp;see more...</p>
      <div class=" rounded-md " id="post_image" style="overflow:hidden;" v-if="post && post.post_images && post.post_images.length">
        <b-carousel  height="auto" id="carousel-fade" :indicators="post.post_images.length>1" :interval="0" :class="post.post_id" :ref="post.post_id">
          <b-button class="leftArrowIcon" v-if="post.post_images.length > 1" @click="showPrevImage(post.post_id)">
            <i class="fa-solid fa-circle-chevron-left"></i>
          </b-button>
          <b-carousel-slide v-for="(postImage, index) in post.post_images" :key="index"  :img-src="postImage.image_medium">
          </b-carousel-slide>
          <b-button class="rightArrowIcon" v-if="post.post_images.length > 1" @click="showNextImage(post.post_id)">
            <i class="fa-solid fa-circle-chevron-right"></i>
          </b-button>
        </b-carousel>
      </div>
    </div>
    <!-- post video  -->
    <div class=" rounded-md ratio ratio-16x9" style="overflow:hidden;" v-if="post && post.post_video">
      <iframe :src="post.post_video" class="rounded" style="width: 100%; height: 380px;" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="comment-area p-3">
      <div class="grid " style="display: grid !important;">
        <div class="d-flex justify-content-center align-items-center like">
          <!-- like button -->
          <button class="btn-opacity rounded-md" @click="postLike(post.post_id, 'POST', post.post_likedby_curr_user)">
            <i v-if="post.post_likedby_curr_user" class="fas fa-thumbs-up text-primary" ></i>
            <i v-else class="far fa-thumbs-up" ></i>
            <span class="ml-2">Like</span>
            <span class="ml-2" :key="post.post_like_count">{{post.post_like_count ? post.post_like_count : ''}}</span>
          </button>
        </div>
        <!-- comment button -->
        <div class="d-flex  justify-content-center align-items-center comment">
          <button class="btn-opacity rounded-md" :key="post.post_comment_count" @click="postCommentShow(post.post_id)">
            <i class="far fa-comment-alt"></i>
            <span class="ml-2">Comment</span>
            <span class="ml-2">{{ post.post_comment_count ? post.post_comment_count : ''}}</span>
          </button>
        </div>
        <!-- share button  -->
        <div v-if="post && post.post_id" class="d-flex flex-fill justify-content-center align-items-center share">
          <SocialMediaShare :propPostId="post.post_id">
          </SocialMediaShare>
        </div>
      </div>

      <!-- comment input box -->
      <div v-if="post.post_show_comment">
        <hr class="mx-0 my-3">
        <div  class="d-flex w-full">
          <div class="user-img">
            <img v-if="userData.user_role == 'USERROLE11111'" :src="gidelogo" alt="" class="avatar-35" rounded="circle" fluid />
            <img v-else-if="getUserProfilePic(postCommentObj)" :src="userData.user_image.profile_pic.image_thumbnail" class="avatar-35" rounded="circle" fluid/>
            <b-avatar v-else :variant="getBgColorForLetter(userData.user_name)" :text="getFirstLetterOfAString(userData.user_name)" class="avatar-35" rounded="circle" fluid>
            </b-avatar>
          </div>
          <b-form class="comment-text d-flex align-items-center ml-3 mb-3 w-full" style="background:#f4f4f4;" action="javascript:void(0);">
            <vue-editor
              v-if='!(userData.user_role == "USERROLE11111" || userData.user_role == "USERROLE11118")'
              v-model="vmCommentFormData.comment"
              placeholder="Write a comment ..."
              :editorToolbar="customToolbar"
              :autofocus="true"
              @keyup.enter="commentAdd()"
              class="w-full"
            />
            <vue-editor
              v-else
              v-model="vmCommentFormData.comment"
              placeholder="Write a comment ..."
              :editorToolbar="customToolbarAdmin"
              :autofocus="true"
              @keyup.enter="commentAdd()"
              class="w-full"
            />

            <div class="comment-attagement d-flex">
              <b-link href="javascript:void(0);">
                <i class="ri-send-plane-line mr-2" @click="commentAdd()"></i>
              </b-link>
            </div>
          </b-form>
        </div>

        <!-- post comment -->
        <ul class="post-comments p-0 m-0" :key="updatedCommentList">
          <li class="mb-2" v-for="(postComment) in  Object.values(postCommentObj)" :key="postComment.comment_id">
            <div class="d-flex w-full">
              <div class="user-img pointer" v-on:click="viewProfile(postComment)">
                <img v-if="postComment.user_admin" :src="gidelogo" alt="" class="avatar-35" rounded="circle" fluid />
                <img v-else-if="postComment.org?.org_profile_img" :src="postComment.org.org_profile_img" alt="" class="avatar-35" rounded="circle" fluid />
                <img v-else-if="postComment['user_image']" :src="postComment['user_image']" class="avatar-35" rounded="circle" fluid/>
                <b-avatar v-else
                  :variant="getBgColorForLetter(postComment.org?.org_name ? postComment.org.org_name : postComment.user_name)"
                  :text="getFirstLetterOfAString(postComment.org?.org_name ? postComment.org.org_name : postComment.user_name)"
                  class="avatar-35" rounded="circle" fluid>
                </b-avatar>
              </div>

              <div class="comment-data-block ml-3 w-full" >
                <div class="d-flex flex-row  justify-content-start w-full">
                  <div class="w-full">
                    <div v-if="postComment.comment_edited">
                      <!-- comment edit form -->
                      <b-form class="comment-text d-flex align-items-center mt-1" action="javascript:void(0);" style="background:#f4f4f4;">
                        <vue-editor
                          v-if='!(userData.user_role == "USERROLE11111" || userData.user_role == "USERROLE11118")'
                          v-model="vmComment"
                          :editorToolbar="customToolbar"
                          :autofocus="true"
                          @keyup.enter="commentEdit(postComment)"
                        />
                        <vue-editor
                          v-else
                          v-model="vmComment"
                          :editorToolbar="customToolbarAdmin"
                          :autofocus="true"
                          @keyup.enter="commentEdit(postComment)"
                        />

                        <div class="comment-attagement d-flex">
                          <b-link href="javascript:void(0);">
                            <i v-b-tooltip.hover.bottomright title="Cancel" class="far fa-times-circle mr-2" @click="commentEditHideInput(postComment)"></i>
                          </b-link>
                          <b-link href="javascript:void(0);">
                            <i v-b-tooltip.hover.bottomright title="Ok" class="fas fa-check mr-1" @click="commentEdit(postComment)"></i>
                          </b-link>
                        </div>
                      </b-form>
                    </div>

                    <!-- comment normal view -->
                    <div v-else class="d-flex justify-content-start" >
                      <div class="d-flex flex-column py-1 px-2 rounded pointer commentBox">
                        <h6 v-on:click="viewProfile(postComment)">
                          {{postComment.org?.org_name ? postComment.org.org_name : postComment.user_name}}
                        </h6>

                        <p class="mb-0">
                          <span v-html="postComment.comment"></span>
                        </p>
                      </div>

                      <div v-if="postComment.user_id === userData.user_id || userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" class="d-flex flex-col  justify-content-center align-items-center ml-3">
                        <b-dropdown  no-caret no-flip  size="sm" variant="light"  style="bacground: #fff !important;" >
                          <template #button-content>
                            <i class="fas fa-ellipsis-h" style="color:#afafaf;"></i>
                          </template>
                          <b-dropdown-item-button @click="commentEditShowInput(postComment)" >
                            Edit
                          </b-dropdown-item-button>
                          <b-dropdown-item-button @click="commentDelete(postComment.comment_id)" >
                            Delete
                          </b-dropdown-item-button>
                        </b-dropdown>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="!postComment.comment_edited" class="d-flex flex-wrap align-items-center comment-activity">
                  <b-link @click="commentLike(postComment.comment_id, 'COMMENT', postComment.comment_likedby_curr_user)">
                    <span class="text-secondary" v-if="postComment.comment_likedby_curr_user">Unlike</span>
                    <span  class="text-primary" v-else>Like</span>
                  </b-link>
                  <b-link href="javascript:void(0);" @click="commentReplyShowInput(postComment)">Reply
                    {{ postComment.comment_reply_count > 0 ? '('+ postComment.comment_reply_count + ')' : ''}}
                  </b-link>
                  <b-link href="javascript:void(0);" @click="ugcx_add(postComment.comment_id, 'COMMENT')">Hide</b-link>
                  <b-link href="javascript:void(0);" @click="showModelReportFn(postComment.comment_id, 'COMMENT')">Report</b-link>
                  <span>{{getFormatttedTimeAgo(postComment.created_on)}}</span>
                </div>
              </div>
            </div>

            <!-- comment reply list -->
            <PostThread @emitUpdatePostComment="updatePostComment" :propPostComment="postComment" :propPostList="postCommentObj" >
            </PostThread>
          </li>
        </ul>
        <p v-if="!allCommentLoaded" >
          <a href="#" @click.prevent="commentList()"> View  more comment </a>
        </p>
      </div>
    </div>
    <span v-if="showModelProfileEdit" scrollable size="md" centered title="Edit Post" hide-footer  no-close-on-backdrop no-close-on-esc  >
      <PostAdd :propPostType="propPostType" :propModuleObjId="propModuleObjId" :propOpenedInModal="showModelProfileEdit" :propPostData="post" @emitCloseEditProfileModal="closeEditProfileModal" :propsPostId="post.post_id"></PostAdd>
    </span>
    <b-modal v-if="(reportModuleObjId && reportModuleName)"  v-model="showModelReport" scrollable size="md" centered title="Report " hide-footer  no-close-on-backdrop no-close-on-esc  >
      <ReportIssue  @emitCloseadminReportIssueAddModal="closeReportAddModal" :propModuleId="reportModuleObjId" :propModuleType="reportModuleName" :propOpenedInModal="true">
      </ReportIssue>
    </b-modal>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
    <AlertBox :key="showUpgradeAlertBox" v-if="showUpgradeAlertBox" :propAlertTitle="alertTitle" :propAlertDesc="alertDesc" :propOkBtnText="okText" :propCancelBtnText="cancelText" @emitCloseAlertModel="showUpgradeAlertBox = false" @emitConfirmAlertModel="goToPlanList()"/>
  </iq-card>
</template>
<script>

import SocialMediaShare from "../../../components/SocialMediaShare.vue"
import PostAdd from "./PostAdd.vue"
import PostThread from "./PostThread.vue"
import { Likes } from "../../../FackApi/api/like.js"
import { Comments } from "../../../FackApi/api/comment.js"
import { Posts } from "../../../FackApi/api/post.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import Utility from "../../../Utils/utility.js"
import ReportIssue from "../../../components/ReportIssue.vue"
import AlertBox from "../../../components/AlertBox.vue"
import { UserContentExcludes } from "../../../FackApi/api/UserContentExclude.js"
import { VueEditor } from "vue2-editor"

export default {
  name: "PostView",
  components: {
    SocialMediaShare,
    PostAdd,
    PostThread,
    ReportIssue,
    AlertBox,
    VueEditor
  },
  props: {
    propPost: {
      type: Object
    },
    propModuleObjId: {
      type: String
    },
    propPostType: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      post: {},
      commentMessage: "",
      loadUserIdForTracking: null,
      showModelProfileEdit: false,
      cvEditComment: false,
      cvLoadingStatus: false,
      postCommentObj: {},
      vmComment: "",
      vmCommentFormData: {},
      vmLikeFormData: {},
      parsedPostImage: [],
      showModelReport: false,
      reportModuleObjId: null,
      reportModuleName: "POST",
      showToast: false,
      toastMsg: null,
      toastVariant: null,
      toastTitle: "Comment",
      showUpgradeAlertBox: false,
      alertDesc: "Please upgrade to Gide Plus",
      alertTitle: "Post",
      okText: "Upgrade",
      cancelText: "Not now",
      gidelogo: process.env.VUE_APP_GIDE_ADMIN_USER_IMAGE,
      updatedCommentList: 1,
      allCommentLoaded: false,
      COMMENT_LIMIT: 5,
      customToolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        ["clean"] // remove formatting button
      ],
      customToolbarAdmin: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video"],
        ["clean"] // remove formatting button
      ]
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    },
    postCommentCount () {
      return this.$store.getters["Post/getPostCommentCount"]
    }
  },
  created () {
    this.post = { ...this.propPost }
    this.vmCommentFormData = Object.assign({}, this.initCommentFormData())
    this.vmLikeFormData = Object.assign({}, this.initLikeFormData())
    this.commentList()
  },
  mounted () {
    this.loadUserIdForTracking = this.userData.user_id
    // The following code appends the user_id of the current user to the trackable url
    Array.from(document.querySelectorAll(`.track${this.propPost.post_id} a`)).forEach(tag => {
      console.log("Attaching events")
      if (tag.href.includes("c.gide.ai")) {
        tag.addEventListener("click", this.addUserIdToTrack)
      }
    })
    this.loadRequiredData()
  },
  beforeDestroy () {
    Array.from(document.querySelectorAll(`.track${this.propPost.post_id} a`)).forEach(tag => {
      tag.removeEventListener("click", this.addUserIdToTrack)
    })
  },
  methods: {
    /**
     * updatePostComment
     */
    updatePostComment (event) {
      if (event.addCommentCount) {
        this.post.post_comment_count += 1
      }
      else if (event.decreaseCommentCount) {
        this.post.post_comment_count -= 1
      }
    },
    /**
     * addUserIdToTrack
     */
    async addUserIdToTrack (event) {
      event.preventDefault()
      let url = event.target.href
      let userId = await this.loadUserIdForTracking

      // appending the user_id into the url
      if (url && url.includes("c.gide.ai")) {
        url = url + `&user_id=${userId}`
        window.open(url, "_blank")
      }
    },
    /**
     * View Follower On click
     */
    viewProfile (comment) {
      let url = `/user/${comment.user_id}`
      if (comment.org_id) {
        url = `/organisation/${comment.org_id}`
      }
      this.$router.push(url)
    },
    /**
     * showAlertForDelete
     */
    async showAlertForDelete (postId) {
      this.deletePostId = postId
      this.alertTitle = "Delete Post"
      this.alertDesc = "Are you sure you want to delete the post ?"
      this.okText = "Yes"
      this.cancelText = "Cancel"
      this.showUpgradeAlertBox = true
    },
    /**
     * showPrevImage ()
     */
    showPrevImage (id) {
      try {
        this.$refs[`${id}`].prev()
      }
      catch (err) {
        console.error("Exception occurred at showPrevImage () and Exception", err.message)
      }
    },
    /**
     * showPrevImage ()
     */
    showNextImage (id) {
      try {
        this.$refs[`${id}`].next()
      }
      catch (err) {
        console.error("Exception occurred at showNextImage () and Exception", err.message)
      }
    },
    /**
     * getBgColorForLetter
     */
    getBgColorForLetter (name) {
      let firstLetter = this.getFirstLetterOfAString(name)
      return Utility.getBgColorForLetter(this, firstLetter)
    },
    /**
     * showModelReportFn
     */
    showModelReportFn (moduleObjId, moduleName) {
      this.reportModuleObjId = moduleObjId
      this.reportModuleName = moduleName
      this.showModelReport = true
    },
    /**
     * closeReportAddModal
     */
    closeReportAddModal () {
      this.showModelReport = false
    },
    /**
     * initCommentFormData
     */
    initCommentFormData () {
      return {
        "module_id": this.post.post_id,
        "module_name": "POST",
        "comment": "",
        "comment_parent_id": ""
      }
    },
    /**
     * initLikeFormData
     */
    initLikeFormData () {
      return {
        "module_id": "",
        "module_name": "",
        "like_status": 0
      }
    },
    /**
     * getUserProfilePic
     */
    getUserProfilePic (comment) {
      if (comment.user_id === this.userData.user_id) {
        if (this.userData.user_image && this.userData.user_image.profile_pic) {
          return this.userData.user_image.profile_pic.image_thumbnail
        }
      }

      return false
    },
    /**
     * getPostImage
     */
    getPostImage (post) {
      if (post.post_type === 5 && post.user_id === this.userData.user_id) {
        if (this.userData.user_image && this.userData.user_image.profile_pic) {
          return this.userData.user_image.profile_pic.image_thumbnail
        }
      }
      return false
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCommentFormData) {
          if (!this.vmCommentFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      console.log("Mounted")
    },
    /**
     * openEditPostModal()
     */
    openEditPostModal () {
      try {
        if (this.showModelProfileEdit) {
          return null
        }
        this.showModelProfileEdit = true
      }
      catch (err) {
        console.error("Exception occurred at  openEditPostModal() and Exception:", err.message)
      }
    },
    /**
     * closeEditProfileModal()
     */
    closeEditProfileModal () {
      try {
        this.showModelProfileEdit = false
      }
      catch (err) {
        console.error("Exception occurred at  closeEditProfileModal() and Exception:", err.message)
      }
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * getFormatttedTimeAgo()
     */
    getFormatttedTimeAgo (dateTime) {
      return Utility.getFormatttedTimeAgo(dateTime)
    },
    /**
     * get Profile Image basis the post type
     */
    getProfileImage (post) {
      switch (post.post_type) {
        case 3: // Clubs
          return post.club.profile_pic.image
        case 1:
        case 2:
        case 4:
        case 5:
        default:
          return post.org && post.org.org_profile_img ? post.org.org_profile_img : post.user_image
      }
    },
    /**
     * get Profile Name basis the post type
     */
    getProfileName (post) {
      switch (post.post_type) {
        case 3: // Clubs
          return post.club.club_name
        case 1:
        case 2:
        case 4:
        case 5:
        default:
          return post.org && post.org?.org_id ? post.org.org_name : post.user_name
      }
    },
    /**
     * goToUserProfile
     */
    goToUserProfile (post) {
      try {
        let url = ""

        if (post.org && post.org.org_id) {
          let orgType = null
          if (post.org.org_id.includes("SCH")) {
            orgType = "SCH"
          }
          else if (post.org.org_id.includes("UNIV")) {
            orgType = "UNIV"
          }
          else if (post.org.org_id.includes("ORG")) {
            orgType = "ORG"
          }

          url = `/organisations/${orgType}/${post.org.org_id}`
        }
        else if (post.post_type === 5 && post.user_admin && post.user_admin.toString() === "1") {
          url = `/user/1`
        }
        else if (post.post_type === 3) {
          url = `/club/${post.club.club_id}`
        }
        else {
          url = `/user/${post.user_id}`
        }

        this.$router.push(url)
      }
      catch (err) {
        console.error("Exception occurred at goToUserProfile() and Exception:", err.message)
      }
    },
    /**
     * goToPostDetail()
     */
    goToPostDetail (postId) {
      try {
        this.$router.push("/post/" + postId)
      }
      catch (err) {
        console.error("Exception occurred at goToPostDetail() and Exception:", err.message)
      }
    },
    /**
     * postCommentShow ()
     */
    postCommentShow (postId) {
      try {
        this.post.post_show_comment = !this.post.post_show_comment
        if (!Object.keys(this.postCommentObj).length) {
          this.commentList()
        }

        this.$store.dispatch("Post/postCommentShowAction", postId)
      }
      catch (err) {
        console.error("Exception occurred at postCommentShow() and Exception:", err.message)
      }
    },
    /**
     * commentList
     */
    async commentList () {
      try {
        let payload = {
          module_id: this.post.post_id,
          limit: this.COMMENT_LIMIT,
          offset: Object.keys(this.postCommentObj).length
        }

        let commentListResp = await Comments.commentList(this, payload)
        if (commentListResp && commentListResp.resp_status) {
          this.postCommentObj = { ...this.postCommentObj, ...commentListResp.resp_data.comment_data }
        }
        else {
          this.allCommentLoaded = true
        }
      }
      catch (err) {
        console.error("Exception occurred at commentList() and Exception:", err.message)
      }
    },
    /**
     * commentAdd
     */
    async commentAdd () {
      try {
        // Format the comment data
        this.vmCommentFormData.comment = this.vmCommentFormData.comment.replace(/\n\r?/g, "<br>")

        this.vmCommentFormData.comment = this.vmCommentFormData.comment.trim()

        this.cvLoadingStatus = true
        let commentAddResp = await Comments.commentAdd(this, this.vmCommentFormData)

        if (!commentAddResp.resp_status && commentAddResp.resp_code === "ERR_USER_NOT_SUBSCRIBED") {
          this.showUpgradeAlertBox = true
          return false
        }
        else if (commentAddResp && !commentAddResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, commentAddResp)
          return false
        }
        else {
          // make comment obj
          let commentAddData = {}
          let commentId = commentAddResp.resp_data.data.comment_id
          commentAddData[commentId] = { ...commentAddResp.resp_data.data }
          commentAddData[commentId].comment_edited = false
          commentAddData[commentId].comment_reply = false
          commentAddData[commentId].comment_show_more = false

          this.postCommentShow(this.vmCommentFormData.postId)

          if (this.userData.user_role === "USERROLE11111") {
            commentAddData[commentId].user_id = "1"
            commentAddData[commentId].user_admin = 1
            commentAddData[commentId].user_name = "Gide"
            commentAddData[commentId].user_image = process.env.VUE_APP_GIDE_ADMIN_USER_IMAGE
          }
          else if (this.userData.user_role === "USERROLE11118") {
            commentAddData[commentId].user_id = "1"
            commentAddData[commentId].user_admin = 1
            commentAddData[commentId].user_name = "Gide Counsellor"
            commentAddData[commentId].user_image = process.env.VUE_APP_GIDE_ADMIN_USER_IMAGE
          }
          else if (this.userData.user_role === "USERROLE11115") {
            // Org
            let orgIds = Object.keys(this.userData.modules.organisations)
            commentAddData[commentId].org = this.userData.modules.organisations[orgIds[0]]
          }
          else if (this.userData.user_role === "USERROLE11116") {
            // Org
            let orgIds = Object.keys(this.userData.modules.organisations)
            commentAddData[commentId].org = this.userData.modules.organisations[orgIds[0]]
          }
          else if (this.userData.user_role === "USERROLE11117") {
            // Org
            let orgIds = Object.keys(this.userData.modules.organisations)
            commentAddData[commentId].org = this.userData.modules.organisations[orgIds[0]]
          }
          else {
            commentAddData[commentId].user_id = this.userData.user_id
            commentAddData[commentId].user_name = this.userData.user_name
            commentAddData[commentId].user_image = this.userData?.user_image?.profile_pic?.image_thumbnail ? this.userData.user_image.profile_pic.image_thumbnail : null
          }

          this.postComment = { ...this.postComment, ...commentAddData }

          // make new  comment obj
          this.postCommentObj = Object.assign({}, { ...commentAddData, ...this.postCommentObj })

          // update post count to vuex store
          let editPostCountData = {}
          editPostCountData.postId = this.vmCommentFormData.module_id
          editPostCountData.updatationType = "ADD"
          this.$store.dispatch("Post/editPostCommentCountAction", editPostCountData)
        }
        this.post.post_comment_count += 1
        this.vmCommentFormData = Object.assign({}, this.initCommentFormData())
      }
      catch (err) {
        console.error("Exception occurred at commentAdd() and Exception:", err)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * commentDelete
     */
    async commentDelete (commentId) {
      try {
        let commentDeleteResp = await Comments.commentDelete(this, commentId)
        await ApiResponse.responseMessageDisplay(this, commentDeleteResp)
        if (!commentDeleteResp.resp_status) {
          return false
        }
        else {
          // update post count to vuex store
          let editPostCountData = {}
          editPostCountData.postId = this.vmCommentFormData.module_id
          editPostCountData.updatationType = "DEL"
          this.$store.dispatch("Post/editPostCommentCountAction", editPostCountData)
          this.post.post_comment_count -= 1
          this.$delete(this.postCommentObj, commentId)
        }
      }
      catch (err) {
        console.error("Exception occurred at commentList() and Exception:", err.message)
      }
    },
    /**
     * commentEdit()
    */
    async commentEdit (postCommentObj) {
      try {
        let editedCommentData = { ...postCommentObj, comment: this.vmComment }
        let commentEditResp = await Comments.commentEdit(this, editedCommentData)
        await ApiResponse.responseMessageDisplay(this, commentEditResp)
        if (commentEditResp && !commentEditResp) {
          this.commentEditHideInput(postCommentObj)
          return false
        }
        else {
          // update the edited value
          let commentId = postCommentObj.comment_id
          this.postCommentObj[commentId] = { ...postCommentObj, comment: this.vmComment, comment_edited: false }
        }
      }
      catch (err) {
        console.error("Exception occurred at commentEdit () and Exception:", err.message)
      }
    },
    async deletePost (postId) {
      try {
        let postDeleteResp = await Posts.postDelete(this, postId)
        await ApiResponse.responseMessageDisplay(this, postDeleteResp)
        this.deletePostId = null
        if (!postDeleteResp.resp_status) {
          return false
        }
        else {
          this.$emit("emitDeletePost", postId)
        }
      }
      catch (err) {
        console.error("Exception occurred at postDelete() and Exception:", err.message)
      }
    },
    /**
     * commentEditShowInput()
     */
    commentEditShowInput (postComment) {
      try {
        // set value to edit from input
        this.vmComment = postComment.comment
        // opening the edit form
        let commentId = postComment.comment_id
        this.postCommentObj[commentId] = { ...postComment, comment_edited: true }
      }
      catch (err) {
        console.error("Exception occurred at commentEditShowInput() and Exception", err.message)
      }
    },
    /**
     * commentEditHideInput()
     */
    commentEditHideInput (postComment) {
      try {
        // set edit form input value to empty string
        this.vmComment = ""
        // closing the edit form
        let commentId = postComment.comment_id
        this.postCommentObj[commentId] = { ...postComment, comment_edited: false }
      }
      catch (err) {
        console.error("Exception occurred at commentEditHideInput() and Exception", err.message)
      }
    },
    /**
     * commentReplyShowInput()
     */
    commentReplyShowInput (postComment) {
      try {
        // opening the reply form
        let commentId = postComment.comment_id
        this.postCommentObj[commentId] = { ...postComment, comment_reply: true }
      }
      catch (err) {
        console.error("Exception occurred at commentReplyShowInput() and Exception", err.message)
      }
    },
    /**
     * postLike()
     */
    async postLike (moduleId, moduleName) {
      try {
        this.vmLikeFormData.module_id = moduleId
        this.vmLikeFormData.module_name = moduleName

        let postLikeCount = this.post.post_like_count ? this.post.post_like_count : 0
        if (!this.post.post_likedby_curr_user) {
          this.vmLikeFormData.like_status = 1
          this.post.post_likedby_curr_user = 1
          postLikeCount += 1
        }
        else if (parseInt(this.post.post_likedby_curr_user) === 1) {
          // 1 for like and 0 for unlike
          this.vmLikeFormData.like_status = 0
          postLikeCount -= 1
          this.post.post_likedby_curr_user = 0
        }

        this.post.post_likedby_curr_user = this.vmLikeFormData.like_status
        let likeAddResp = await Likes.likeAdd(this, this.vmLikeFormData)
        if (likeAddResp && !likeAddResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, likeAddResp)
        }
        else {
          this.post.post_like_count = postLikeCount
        }
      }
      catch (err) {
        console.error("Exception occurred at  postLike () and Exception", err.message)
      }
    },
    /**
     * commentLike
    */
    async commentLike (moduleId, moduleName, likeStatus) {
      try {
        this.vmLikeFormData.module_id = moduleId
        this.vmLikeFormData.module_name = moduleName
        this.vmLikeFormData.like_status = likeStatus ? 0 : 1
        let likeAddResp = await Likes.likeAdd(this, this.vmLikeFormData)
        if (likeAddResp && !likeAddResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, likeAddResp)
        }
        else {
          let likeUnlikeData
          if (this.vmLikeFormData.like_status) {
            likeUnlikeData = { ...this.postCommentObj[moduleId], comment_likedby_curr_user: 1 }
          }
          else {
            likeUnlikeData = { ...this.postCommentObj[moduleId], comment_likedby_curr_user: 0 }
          }
          this.postCommentObj[moduleId] = likeUnlikeData
        }
      }
      catch (err) {
        console.error("Exception occurred at commentLike () and Exception", err.message)
      }
    },
    /**
     * showMoreComment()
     */
    showMoreComment (postCommentObj) {
      try {
        let commentId = postCommentObj.comment_id
        this.postCommentObj[commentId] = { ...postCommentObj, comment_show_more: true }
      }
      catch (err) {
        console.error("Exception occurred at showMoreComment() and Exception:", err.message)
      }
    },
    /**
     * openDetailPage ()
     */
    openDetailPage (postId) {
      try {
        this.$emit("emitPostDetailModalOpen", postId)
      }
      catch (err) {
        console.error("Exception occurred at openDetailPage() and Exception:", err.message)
      }
    },
    /**
     * goToPlanList
     */
    async goToPlanList () {
      if (this.deletePostId) {
        this.deletePost(this.deletePostId)
      }
      else {
        this.$router.push("/subscription_plans")
      }
    },
    /**
     * ugcx_add
     */
    async ugcx_add (moduleObjId, moduleName) {
      this.cvLoadingStatus = true

      try {
        let userContentExcludeObj = {}
        userContentExcludeObj.module_obj_id = moduleObjId
        userContentExcludeObj.module_name = moduleName

        let ugcxResp = await UserContentExcludes.userContentExcludeAdd(this, userContentExcludeObj)
        if (ugcxResp.resp_status) {
          if (moduleName === "COMMENT") {
            // update post count to vuex store
            let editPostCountData = {}
            editPostCountData.postId = this.vmCommentFormData.module_id
            editPostCountData.updatationType = "DEL"
            this.$store.dispatch("Post/editPostCommentCountAction", editPostCountData)
            this.post.post_comment_count -= 1
            this.$delete(this.postCommentObj, moduleObjId)
            this.updatedCommentList += 1
          }
          else {
          // Delete the post from the list
            this.$emit("emitDeletePost", moduleObjId)
            return false
          }
        }
        else {
          ApiResponse.responseMessageDisplay(this, ugcxResp)
          return false
        }
      }
      catch (err) {
        console.error("Exception occurred at ugcx_add() and Exception:", err)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>

<style lang="scss">
  .dropdown-item{
    color: #212529 !important;
  }
  .dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover{
    background: transparent;
    color: var(--iq-primary) !important;
  }
  .logo-icon{
    height: 50px !important;
    width: 50px !important;
    overflow: hidden;
    object-fit: cover;
    pointer-events: none;
    border-radius: 50%;
  }
  .w-full{
    width: 100%;
  }
  .btn-opacity{
    border: none ;
    outline : none;
    background:#fff;
    width: 100%;
  }
  .btn-opacity:hover{
    color: var(--iq-primary)
  }
  .line-wrap{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
  .grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr)
  }
  @media screen and (max-width: 768px){
    .grid{
       grid-template-columns: 0.9fr 1.2fr 0.9fr
    }
  }
  #photo-grid img {
    background: #FFFFFF!important;
    object-fit: fill;
  }
  .leftArrowIcon {
    position: absolute;
    left: 0px;
    z-index: 99;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 32px;
    background-color: transparent !important;
    border: none;
    i {
      font-size: 22px;
    }
  }
  .rightArrowIcon {
    position: absolute;
    right: 0px;
    z-index: 99;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 32px;
    background-color: transparent !important;
    border: none;
    i {
      font-size: 22px;
    }
  }
  .comment-attagement{
    bottom: 2px;
    right: 2px;
  }
</style>
