<template>
  <div>
    <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
      <template v-slot:headerTitle v-if="!propOpenedInModal">
        <h4 class="card-title">
          <i class="fa fa-solid fa-newspaper-o" aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
          Create Post
        </h4>
      </template>
      <hr class="m-0"/>
      <div v-if="!propOpenedInModal" class="iq-card-body" @click="showPostAddModal()" style="cursor: pointer;">
        <div class="d-flex align-items-center">
          <div class="user-img">
            <UserLogo />
          </div>
          <span>Write your post ...</span>
        </div>
        <hr />
        <ul class="post-opt-block d-flex align-items-center list-inline m-0 p-0" v-if="(!propPostType || propPostType !== 3) && showPhotoVideoOption">
          <li class="iq-bg-primary rounded p-2 pointer mr-3">
            <a href="#"></a>
            <i class="far fa-image"></i>
            Photo
          </li>
          <li class="iq-bg-primary rounded p-2 pointer mr-3">
            <a href="#"></a>
            <i class="fas fa-video"></i>
            Video
          </li>
        </ul>
      </div>

      <b-modal v-model="vmPostAddModal" @hide="hideModal" scrollable size="md" centered :title="propOpenedInModal ? 'Edit Post': 'Create Post'" hide-footer no-close-on-backdrop no-close-on-esc >
        <div @dragenter="dragImageStart">
          <div>
            <form  class="post-text m-1 mt-2"  style="width: 100%; position: relative">
              <!-- input  -->
              <input type="file" placeholder="Choose post image to upload" :class="['dropZone', cvdragImageFile ? 'dropZone-over' : '']" id="inputPhoto1" ref="imageFileUploader"  @change="onImageChange" @dragleave="dragImageEnd" multiple accept="image/png, image/jpeg" />

              <vue-editor
                v-if='!(userData.user_role == "USERROLE11111" || userData.user_role == "USERROLE11118")'
                v-model="vmPostFormData.post_desc"
                placeholder="Write something here"
                :editorToolbar="customToolbar"
                :autofocus="cvPostDescAutoFocus"
              ></vue-editor>
              <vue-editor
                v-else
                v-model="vmPostFormData.post_desc"
                placeholder="Write something here"
                :editorToolbar="customToolbarAdmin"
                :autofocus="cvPostDescAutoFocus"
              ></vue-editor>

              <div v-if="cvImageReading" class="d-flex flex-column  justify-content-center align-items-center">
                <b-spinner label="Spinning"></b-spinner>
                processing ...
              </div>
              <div v-else>
                <!-- image display -->
                <div v-if="vmImagePreview && vmImagePreview.length > 0" :key="vmImagePreview.length+'vmImagePreviewlength'" style="overflow: hidden; position: relative; width: 100%; margin-top: 3px; border-radius: 5px;"  >
                  <div id="photo-grid"  class="row" v-if="Array.isArray(vmImagePreview)">
                    <!-- <photo-grid :box-height="'400px'" :box-width="'100%'"   class="my-2 rounded" :boxBorder="2" > -->
                    <template v-for="(postImage, index) in vmImagePreview">
                      <div class="imageContainer" :key="index+'pg'">
                        <img
                          :src="postImage.image_medium"
                          :key="index"
                          style="background: #FFFFFF;"/>
                        <!-- style=" position: absolute; display: flex;justify-content: center; align-items: center; top: 10px; right: 10px;  z-index: 999;  height: 30px; width: 30px;  border-radius: 50%; border: none;  margin-left: auto; cursor: pointer" -->
                        <button type="button" class="deleteImgIcon" @click="removeImage(index)"  >
                          <i class="fas fa-times" style="color: #333; font-size: 16px"></i>
                        </button>
                      </div>
                    </template>
                    <!-- </photo-grid> -->
                  </div>
                  <!-- remove image button -->
                </div>
                <!-- video display  -->
                <div v-if="vmVideoData && vmVideoData.embeded" class="mt-2 ratio ratio-16x9" style="overflow: hidden; position: relative; width: 100%; height: 250px; border-radius: 5px;">
                  <!-- <video :src="vmVideoData.video" autoplay controls style="width:100%; height:250px;"></video> -->
                  <iframe :src="vmVideoData.embeded" class="rounded" style="width: 100%; height: 250px;" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  <button @click="removeVideo()" type="button" style=" position: absolute; display: flex;justify-content: center; align-items: center; top: 10px; right: 10px;  z-index: 999;  height: 30px; width: 30px;  border-radius: 50%; border: none;  margin-left: auto; cursor: pointer" >
                    <i class="fas fa-times" style="color: #333; font-size: 16px"></i>
                  </button>
                </div>
              </div>

              <b-alert v-model="cvImageErrorAlert" class="mt-2" variant="danger" show dismissible fade>
                {{errorMsg}}
              </b-alert>

              <div class="mt-3">
                <label>{{cvPostAsLabel}}</label>
                <multiselect
                  v-model="vmPostFormData.post_module_obj_id"
                  :options="cvPostAsOptions"
                  track-by="id"
                  label="name"
                  :placeholder="cvSelectLabel + cvPostAsLabel"
                  :tag-placeholder="cvSelectLabel + cvPostAsLabel">
                </multiselect>
              </div>

              <div class="mt-3" v-if="vmPostVideoLink">
                <label>{{cvPostVideoLink}}</label>
                <input v-model="vmVideoData.video" type="text" class="form-control" :placeholder="cvPostVideoLink" required="true" />
                <span v-show="cvVideoError" class="text-danger" >{{videoErrMsg}}</span>
              </div>
            </form>
          </div>
          <hr />

          <ul class=" d-flex flex-column flex-wrap  align-items-start list-inline m-0  p-0 " v-if="showPhotoVideoOption" >
            <li v-for="(item, index) in tab" :key="index">
              <div v-if="item.name == 'Photo'">
                <label for="inputPhoto1" @click="hideVideo()" >
                  <div class="iq-bg-primary rounded p-1 pl-2 pr-2 pointer mr-3" style="cursor: pointer">
                    <!-- <a href="#"></a> -->
                    <i :class="item.icon"></i>
                    {{ item.name }} <span style="font-size: 11px;margin-left: 3px;">(Image size must be 1080(w) * 1080(h))</span>
                  </div>
                </label>
              </div>
              <div v-else @click="showPostVideoLink">
                <label for="" >
                  <div class="iq-bg-primary rounded p-1 pl-2 pr-2 mr-3" style="cursor: pointer">
                    <a href="#"></a>
                    <i :class="item.icon"></i>
                    {{ item.name }}
                  </div>
                </label>
              </div>
            </li>
          </ul>
          <button v-if="cvLoadingStatus"  class="btn btn-primary d-block w-100 mt-3" :disabled="cvLoadingStatus || cvImageError" >
            Please wait creating post ...
          </button>
          <button v-else-if="cvAddingImg"  class="btn btn-primary d-block w-100 mt-3" :disabled="cvAddingImg || cvImageError" >
            Please wait adding images ...
          </button>
          <button v-else-if="cvVideoLoading"  class="btn btn-primary d-block w-100 mt-3" :disabled="cvVideoLoading || cvVideoError" >
            Please wait adding video ...
          </button>
          <button v-else class="btn btn-primary d-block w-100 mt-3 mb-3" :disabled="cvLoadingStatus || cvImageError || cvVideoError" @click="postAdd()" >
            Post
          </button>
        </div>
      </b-modal>
    </iq-card>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import UserLogo from "../../../components/socialvue/navbars/UserLogo.vue"
import { Posts } from "../../../FackApi/api/post"
import { Images } from "../../../FackApi/api/image"
import { Videos } from "../../../FackApi/api/video"
import ApiResponse from "../../../Utils/apiResponse.js"
import Multiselect from "vue-multiselect"
import { EventBus } from "../../../main"
import { VueEditor } from "vue2-editor"

export default {
  name: "PostAdd",
  components: {
    UserLogo,
    Multiselect,
    VueEditor
  },
  props: {
    propsPostId: {
      type: String
    },
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propPostData: {
      default: Object
    },
    propPostType: {
      type: Number,
      default: null
    },
    propModuleObjId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      cvSelectLabel: "Select ",
      cvPostTitleLabel: "Post Title",
      cvPostDescLabel: "Post Description",
      cvPostImageLabel: "Post Image",
      cvPostTypeLabel: "Post Type",
      cvPostUrlLabel: "Post URL",
      cvPostAsLabel: "Post As",
      cvPostVideoLink: "Video Link",
      cvdragImageFile: false,
      cvLoadingStatus: false,
      cvImageReading: false,
      cvPostDescAutoFocus: true,
      cvVideoLoading: false,
      cvPhotoGridHeight: "240px",
      cvPhotoGridWidth: "100%",
      cvImageError: false,
      cvImageErrorAlert: false,
      cvVideoError: false,
      cvImageExtensionErrorMsg: " Only jpeg, webp and png is supported.",
      cvImageSizeErrorMsg: " Image size must be less than 4 mb.",
      cvImageMaxNoErrorMsg: "You can only select maximum 10 image",
      cvVideoUrlMsg: "Only youtube url is supported",
      cvVideoUrlValidMsg: "Please only valid youtue video",
      errorMsg: "",
      videoErrMsg: "",
      vmImagePreview: [],
      cvPostAsOptions: [],
      vmPostFormData: Object.assign({}, this.initFormData()),
      vmPostTags: Object.assign({}, this.initTagData()),
      vmImageData: Object.assign({}, this.initImageData()),
      vmVideoData: Object.assign({}, this.initVideoData()),
      tab: [
        {
          icon: "far fa-image",
          name: "Photo"
        },
        {
          icon: "fas fa-video",
          name: "Video"
        }
      ],
      propPostUserData: {},
      vmPostAddModal: false,
      vmPostVideoLink: false,
      cvAddingImg: false,
      showToast: false,
      toastTitle: "POST ADD",
      toastMsg: null,
      toastVariant: "",
      showPhotoVideoOption: false,
      customToolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        ["clean"] // remove formatting button
      ],
      customToolbarAdmin: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video"],
        ["clean"] // remove formatting button
      ]
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    if (this.propOpenedInModal) {
      this.vmPostAddModal = true
      this.toastTitle = "POST EDIT"
      this.postView()
    }
    this.getPostAsList()
  },
  methods: {
    /**
     * hide
     */
    hideModal () {
      this.$emit("emitCloseEditProfileModal")
    },
    hideVideo () {
      this.vmPostVideoLink = false
      this.vmVideoData = Object.assign({}, this.initVideoData())
    },
    /**
     * initFormData
     */
    initFormData () {
      return {
        post_title: "",
        post_desc: "",
        post_type: "",
        post_module_obj_id: ""
      }
    },
    /**
     * initTagData
     */
    initTagData () {
      return {
        tag_type: "",
        tag: [],
        module_id: "",
        module_type: ""
      }
    },
    /**
     * initImageData
    */
    initImageData () {
      return {
        module_id: "",
        module_name: "POST",
        image: [],
        image_type: {}
      }
    },
    /**
    * postView
    */
    async postView () {
      try {
        this.cvLoadingview = true
        /* let postViewResp = await Posts.postView(this, this.propsPostId)
        if (postViewResp && !postViewResp.resp_status) {
          return false
        } */
        this.vmPostFormData = { ...this.propPostData }

        if (this.vmPostFormData.post_images && this.vmPostFormData.post_images.length) {
          this.vmImagePreview = [ ...this.vmPostFormData.post_images ]
        }
        else {
          this.vmImagePreview = []
        }

        if (this.vmPostFormData.post_video) {
          this.vmVideoData = {
            module_id: this.propsPostId,
            module_name: "POST",
            video: this.vmPostFormData.post_video,
            embeded: this.vmPostFormData.post_video,
            video_id: this.vmPostFormData.video_id
          }
          this.showPostVideoLink()
        }
      }
      catch (err) {
        console.error("Exception occurred at postView() and Exception:", err)
      }
      finally {
        this.cvLoadingview = false
      }
    },
    /**
     * initVideoData
     */
    initVideoData () {
      return {
        module_id: "",
        module_name: "POST",
        video: "",
        embeded: "",
        video_id: null
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      this.propPostUserData = Object.assign({}, this.userData)
      this.vmImagePreview = []
      this.getPostAsList()
      this.initFormData()
      this.initImageData()
      this.initTagData()
    },
    /**
     * showPostAddModal
     */
    showPostAddModal () {
      this.loadRequiredData()
      this.vmPostAddModal = true
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmPostFormData) {
          if (!this.vmPostFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error(
          "Exception occurred at validate() and Exception:",
          err.message
        )
      }
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     *add post
     */
    async postAdd () {
      if (this.cvImageError) {
        return
      }

      if (this.cvVideoError) {
        return
      }

      if (!this.vmPostFormData.post_module_obj_id) {
        return
      }

      try {
        if (this.vmPostFormData.post_desc || this.vmImageData.image.length > 0 || this.vmVideoData.embeded) {
          this.cvLoadingStatus = true
          let postApiResp = null

          this.vmPostFormData.post_desc = this.vmPostFormData.post_desc.trim()

          if (this.propModuleObjId && this.vmPostFormData.post_module_obj_id) {
            // if univ, school or organisation posting into club
            let moduleData = { ...this.vmPostFormData.post_module_obj_id }
            this.vmPostFormData.user_type = moduleData.post_type
            this.vmPostFormData.user_module_obj_id = moduleData.id

            this.vmPostFormData.post_module_obj_id.id = this.propModuleObjId
            this.vmPostFormData.post_module_obj_id.post_type = this.propPostType
          }
          else {
            this.vmPostFormData.user_type = this.vmPostFormData.post_module_obj_id.post_type
            this.vmPostFormData.user_module_obj_id = this.vmPostFormData.post_module_obj_id.id
          }

          if (this.userData.user_role == "USERROLE11114") {
            // For Student's Allow to post, comment.
            this.vmPostFormData.user_module_obj_id = this.userData.user_id
          }

          if (this.propsPostId) {
            postApiResp = await Posts.postEdit(this, this.vmPostFormData)
          }
          else {
            this.vmPostFormData.post_type = this.vmPostFormData.post_module_obj_id.post_type
            postApiResp = await Posts.postAdd(this, this.vmPostFormData)
          }

          if (postApiResp && !postApiResp.resp_status) {
            await ApiResponse.responseMessageDisplay(this, postApiResp)
            return false
          }

          // add post to vuex store
          if (postApiResp.resp_status) {
            let newPost = {}
            let postId

            if (this.propsPostId) {
              // Edit
              postId = this.propsPostId
              newPost[this.propsPostId] = this.vmPostFormData
              if (!newPost[this.propsPostId].update) {
                newPost[this.propsPostId].update = 0
              }
              else {
                newPost[this.propsPostId].update++
              }
            }
            else {
              postId = postApiResp.resp_data.post_data.post_id
              newPost[postId] = postApiResp.resp_data.post_data
              newPost[postId].user_image = this.userData?.user_image?.profile_pic?.image_thumbnail ? this.userData.user_image.profile_pic.image_thumbnail : null
              newPost[postId].user_name = this.userData.user_name
              newPost[postId].post_like_count = 0
              newPost[postId].post_likedby_curr_user = 0
              newPost[postId].post_show_comment = false

              if (this.vmPostFormData.post_module_obj_id) {
                let moduleObj = this.vmPostFormData.post_module_obj_id

                // Type = 1 is University
                if (moduleObj.post_type === 1) {
                  newPost[postId].module_data = moduleObj.module_data
                  newPost[postId].module_obj_name = moduleObj.module_data.org_name
                  newPost[postId].module_obj_image = moduleObj.module_data?.profile_pic ? moduleObj.module_data.profile_pic : null
                  newPost[postId].post_type_name = "org"
                  newPost[postId].org = {
                    org_id: moduleObj.module_data.org_id,
                    org_name: moduleObj.module_data.org_name,
                    org_image: moduleObj.module_data?.profile_pic
                  }
                }
                else if (moduleObj.post_type === 2) {
                  // School
                  newPost[postId].module_data = moduleObj.module_data
                  newPost[postId].module_obj_name = moduleObj.module_data.sch_name
                  newPost[postId].module_obj_image = moduleObj.module_data?.profile_pic ? moduleObj.module_data.profile_pic : null
                  newPost[postId].post_type_name = "sch"
                  newPost[postId].sch = {
                    sch_id: moduleObj.module_data.sch_id,
                    sch_name: moduleObj.module_data.sch_name,
                    sch_image: moduleObj.module_data?.profile_pic
                  }
                }
                else if (moduleObj.post_type === 3) {
                  newPost[postId].module_data = moduleObj.module_data
                  // Organisation
                  newPost[postId].module_data = moduleObj.module_data
                  newPost[postId].module_obj_image = moduleObj.module_data?.profile_pic ? moduleObj.module_data.profile_pic : null

                  if (this.userData.user_role === "USERROLE11111" || this.userData.user_role === "USERROLE11118") {
                    newPost[postId].user_id = "1"
                    newPost[postId].user_admin = 1
                    newPost[postId].user_name = "Gide"
                    newPost[postId].user_image = this.userData?.user_image?.profile_pic?.image_thumbnail ? this.userData.user_image.profile_pic.image_thumbnail : null
                  }
                  else {
                    newPost[postId].post_type_name = "club"
                    // check who posted this
                    if (newPost[postId].user_type == 1) {
                      // University
                      newPost[postId].post_type_name = "org"
                      newPost[postId].org = {
                        org_id: moduleObj.module_data.org_id,
                        org_name: moduleObj.module_data.org_name,
                        org_image: moduleObj.module_data?.profile_pic
                      }
                    }
                    else if (newPost[postId].user_type == 2) {
                      // school
                      newPost[postId].post_type_name = "sch"
                      newPost[postId].sch = {
                        sch_id: moduleObj.module_data.sch_id,
                        sch_name: moduleObj.module_data.sch_name,
                        sch_image: moduleObj.module_data?.profile_pic
                      }
                    }
                    else if (newPost[postId].user_type == 6) {
                      // organisation
                      newPost[postId].post_type_name = "org"
                      newPost[postId].org = {
                        org_id: moduleObj.module_data.org_id,
                        org_name: moduleObj.module_data.org_name,
                        org_image: moduleObj.module_data?.profile_pic
                      }
                    }
                  }
                }
                else if (moduleObj.post_type === 5) {
                  // user, admin
                  newPost[postId].user_admin = (this.userData.user_role === "USERROLE11111" || this.userData.user_role === "USERROLE11118") ? 1 : 0
                  newPost[postId].post_type_name = "user"
                  newPost[postId].user_name = this.userData.user_role === "USERROLE11118" ? "Gide Counsellor" : moduleObj.name
                  newPost[postId].user_name = this.userData.user_role === "USERROLE11111" ? "Gide" : newPost[postId].user_name
                  newPost[postId].user_image = newPost[postId].user_admin === 1 ? process.env.VUE_APP_GIDE_ADMIN_USER_IMAGE : this.userData.user_image
                }
                else if (moduleObj.post_type === 6) {
                  // Organisation
                  newPost[postId].module_data = moduleObj.module_data
                  newPost[postId].module_obj_name = moduleObj.module_data.org_name
                  newPost[postId].module_obj_image = moduleObj.module_data?.profile_pic ? moduleObj.module_data.profile_pic : null
                  newPost[postId].post_type_name = "org"
                  newPost[postId].org = {
                    org_id: moduleObj.module_data.org_id,
                    org_name: moduleObj.module_data.org_name,
                    org_image: moduleObj.module_data?.profile_pic
                  }
                }
              }
            }

            // calling the image add api
            if (this.vmImageData.image.length > 0) {
              this.cvLoadingStatus = false
              await this.imageAdd(postId)
              newPost[postId].post_images = this.vmImagePreview
            }

            if ((!this.vmVideoData.video && this.vmPostFormData.post_video) || (this.vmVideoData.video !== this.vmPostFormData.post_video && this.vmPostFormData.video_id)) {
              // If diffrent video added or no video added
              await Videos.videoDelete(this, this.vmPostFormData.video_id)
              this.vmVideoData.video_id = null
              this.vmPostFormData.post_video = null
              this.vmPostFormData.video_id = null
            }

            // caling the video add api
            if (this.vmVideoData.video) {
              if (this.propsPostId && this.vmPostFormData.post_images.length) {
                await this.imageDeletebyModuleId(this.propsPostId)
                this.vmPostFormData.post_images = []
              }

              if (!this.vmVideoData.video_id) {
                const videoResp = await this.videoAdd(postId)
                if (videoResp.resp_status) {
                  this.vmPostFormData.post_video = videoResp.resp_data.embeded
                  this.vmPostFormData.video_id = videoResp.resp_data.video_id

                  newPost[postId].post_video = this.vmVideoData.embeded
                }

                this.vmPostFormData.post_images = []
              }

              this.cvLoadingStatus = false
            }

            if (!this.propsPostId) {
              // If Post Add
              EventBus.$emit("emitPostAdd", newPost)
              if (this.vmPostFormData.post_module_obj_id.post_type !== 3) {
                // for post publish in clubs donot push them to the main post screen
                this.$store.dispatch("Post/setNewPostAction", newPost)
              }
            }
            else {
              if (this.vmPostFormData.update) {
                this.vmPostFormData.update += 1
              }
              else {
                this.vmPostFormData.update = 1
              }

              EventBus.$emit("emitPostAdd", newPost)
              this.$emit("emitPostAdd", { ...this.vmPostFormData })
              this.$store.dispatch("Post/editPostAction", { ...this.vmPostFormData })
            }

            // saving the image  of post
            // let newImagesData = { module_id: postId, images: newPost[postId].post_images }
            // this.$store.dispatch('Post/setNewPostImageAction', newImagesData)
          }

          this.vmPostFormData = Object.assign({}, this.initFormData())
          this.$emit("emitCloseEditProfileModal")
          this.$bvModal.hide("postAddModal")
          this.vmImagePreview = []
          this.vmPostAddModal = false
        }
      }
      catch (err) {
        console.error("Exception occurred at postAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * imageAdd
     */
    async imageAdd (moduleId) {
      this.cvAddingImg = true
      try {
        this.vmImageData.module_id = moduleId
        const imageAddResp = await Images.imageAdd(this, this.vmImageData)

        if (imageAddResp && !imageAddResp.resp_status) {
          this.toastTitle = "Post Image Add"
          this.toastMsg = imageAddResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true

          this.vmImagePreview = []
          this.vmPostFormData.post_images = []

          this.cvAddingImg = false
          return false
        }

        if (!this.vmPostFormData.post_images) {
          this.vmPostFormData.post_images = []
        }

        this.vmPostFormData.post_images = [ ...this.vmPostFormData.post_images, ...imageAddResp.resp_data ]
        this.vmImageData = Object.assign({}, this.initImageData())
        this.cvAddingImg = false
      }
      catch (err) {
        this.cvAddingImg = false
        console.error("Exception occurred at imageAdd() and Exception:", err.message)
      }
    },
    /**
     *  imageDeletebyModuleId
    */
    async imageDeletebyModuleId (moduleId) {
      try {
        let imageDeleteResp = await Images.imageDelete(this, moduleId)
        if (imageDeleteResp && !imageDeleteResp.resp_status) {
          return false
        }
      }
      catch (err) {
        console.error("Exception occurred at imageDelete() and Exception:", err.message)
      }
    },
    /**
     * videoAdd
     */
    async videoAdd (moduleId) {
      this.cvVideoLoading = true
      try {
        this.vmVideoData.module_id = moduleId
        let videoAddResp = await Videos.videoAdd(this, this.vmVideoData)
        await ApiResponse.responseMessageDisplay(this, videoAddResp)
        if (videoAddResp && !videoAddResp.resp_status) {
          return false
        }
        this.cvVideoLoading = false
        return videoAddResp
      }
      catch (err) {
        this.cvVideoLoading = false
        console.error("Exception occurred at videoAdd() and Exception:", err.message)
      }
    },
    /**
     * dragImageStart
     */
    dragImageStart (e) {
      try {
        this.cvdragImageFile = true
      }
      catch (err) {
        console.error("Exception occurred at dragImageStart () and Exception:", err.message)
      }
    },
    /**
     * dragImageEnd
     */
    dragImageEnd (e) {
      try {
        this.cvdragImageFile = false
      }
      catch (err) {
        console.error("Exception occurred at dragImageEnd () and Exception:", err.message)
      }
    },
    /**
     * onImageChange
     */
    onImageChange (e) {
      try {
        this.cvdragImageFile = false
        let input = e.target
        if (input.files.length > 0) {
          this.cvImageReading = true

          let maxNoOfImage = 10
          let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
          let maxSize = 4 * 1024 * 1024

          let imageValidExtension = input.files.length > 0 ? Object.values(input.files).map(file => allowedExtension.includes(file.type)) : null
          let imageValidSize = input.files.length > 0 ? Object.values(input.files).map(file => file.size < maxSize) : null

          // image validation and error message
          if (input.files.length > maxNoOfImage || this.vmImagePreview.length >= maxNoOfImage) {
            this.cvImageError = true
            this.cvImageErrorAlert = true
            this.errorMsg = this.cvImageMaxNoErrorMsg
          }
          else if (imageValidExtension && imageValidExtension.includes(false)) {
            this.cvImageError = true
            this.cvImageErrorAlert = true
            this.errorMsg = this.cvImageExtensionErrorMsg
          }
          else if (imageValidSize && imageValidSize.includes(false)) {
            this.cvImageError = true
            this.cvImageErrorAlert = true
            this.errorMsg = this.cvImageSizeErrorMsg
          }
          else {
            this.cvImageError = false
            this.cvImageErrorAlert = false
            this.errorMsg = ""
          }

          // if image has error then return from function
          if (this.cvImageError) {
            this.cvImageReading = false
            setTimeout(() => {
              this.cvImageError = false
            }, 100)
            setTimeout(() => {
              this.cvImageErrorAlert = false
            }, 5000)
            return
          }

          let images = Object.values(input.files).slice(0, 5)

          let self = this
          images.forEach((image, index, imagesArr) => {
            let imageData = {}
            const reader = new FileReader()
            reader.onload = async (e) => {
              // Initiate the JavaScript Image object.
              var image = new Image()

              // Set the Base64 string return from FileReader as source.
              image.src = e.target.result

              // Validate the File Height and Width.
              image.onload = async function () {
                var height = this.height
                var width = this.width
                if (height !== 1080 || width !== 1080) {
                  self.toastMsg = "Looks like one or more images do not match the requirements. Allowed height 1080px and width 1080px."
                  self.showToast = true
                  self.toastVariant = "danger"
                }
                else {
                  self.vmImageData.image_type[image.name] = 1
                  let dataUrl = await e.target.result
                  imageData = {
                    image: "",
                    image_medium: dataUrl,
                    image_thumbnail: ""
                  }
                  self.vmImageData.image.push(images[index])
                  self.vmImagePreview.push(imageData)
                }
              }
            }

            reader.readAsDataURL(image)
            imageData.image_medium = ""

            // when last image filereader process is end then then stop the image processing
            if (index === imagesArr.length - 1) {
              reader.onloadend = (e) => {
                this.cvImageReading = false
              }
            }
          })
          const inputPhotoEle = document.getElementById("inputPhoto1")
          if (inputPhotoEle) {
            inputPhotoEle.value = null
          }
        }
      }
      catch (err) {
        this.cvImageReading = false
        console.error("Exception occurred at onImageChange() and Exception:", err.message)
      }
    },
    /**
     * removeImage
     */
    async removeImage (index) {
      try {
        if (this.vmImagePreview[index].image_id) {
          const value = await this.$bvModal.msgBoxConfirm("Are you sure you want to delete image?")
          if (value) {
            const deleteResp = await Images.imageDeleteByID(this, this.vmImagePreview[index].image_id)
            if (!deleteResp.resp_status) {
              return
            }

            const delIndex = this.vmPostFormData.post_images.findIndex(img => img.image_id === this.vmImagePreview[index].image_id)
            if (delIndex >= 0) {
              this.vmPostFormData.post_images.splice(delIndex, 1)
            }
          }
          else {
            return
          }
        }
        // this.vmImageData.image = []
        // this.vmImageData.image_type = {}
        if (this.vmImageData.images && this.vmImageData.images.length) {
          const findIndex = this.vmImageData.images.findIndex(image => image.image_medium === this.vmImagePreview[index].image_medium)
          if (findIndex >= 0) {
            this.vmImageData.images.splice(findIndex, 1)
          }
        }
        this.vmImagePreview.splice(index, 1)
        this.errorMsg = ""
      }
      catch (err) {
        console.error(
          "Exception occurred at removeImage () and Exception:",
          err.message
        )
      }
    },
    /**
     * removeVideo
     */
    async removeVideo () {
      try {
        if (this.propsPostId && this.vmVideoData && this.vmVideoData.video_id) {
          const value = await this.$bvModal.msgBoxConfirm("Are you sure you want to delete video?")
          if (value) {
            const deleteResp = await Videos.videoDelete(this, this.vmVideoData.video_id)
            if (!deleteResp.resp_status) {
              return
            }
          }
          else {
            return
          }
        }
        this.vmPostVideoLink = false
        this.vmPostFormData.post_video = null
        this.vmPostFormData.video_id = null
        this.vmVideoData = Object.assign({}, this.initVideoData())
      }
      catch (err) {
        console.error("Exception occurred at removeVideo() and Exception:", err.message)
      }
    },
    /**
     * getPostAsList
     */
    async getPostAsList () {
      try {
        // Add user to post as option list
        let postAsUserOption = { name: `${this.userData.user_name} (You)`, id: this.userData.user_id, post_type: 5 }

        if (this.propModuleObjId && this.propPostType) {
          postAsUserOption.id = this.propModuleObjId
          postAsUserOption.post_type = this.propPostType
          postAsUserOption.module_data = {
            club_name: this.userData.user_name,
            org_name: this.userData.user_name
          }
        }
        this.cvPostAsOptions = []

        this.vmPostFormData.post_module_obj_id = {}

        if (this.userData.user_role === "USERROLE11111" ||
          this.userData.user_role === "USERROLE11114" ||
          this.userData.user_role === "USERROLE11112" ||
          this.userData.user_role === "USERROLE11116" ||
          this.userData.user_role === "USERROLE11117" ||
          this.userData.user_role === "USERROLE11118"
        ) {
          this.cvPostAsOptions.push(postAsUserOption)
          this.vmPostFormData.post_module_obj_id = postAsUserOption
        }

        // If user user is part of other modules
        if (this.userData.modules) {
          // Add clubs to post as option list
          if (this.userData.modules.clubs) {
            let clubs = this.userData.modules.clubs
            for (let index in clubs) {
              let club = clubs[index]
              let allowedToPost = this.getUserAllowedToPostOrNot(club.club_id)
              if (allowedToPost) {
                this.cvPostAsOptions.push(
                  { name: `${club.club_name} (Club)`, id: club.club_id, post_type: 3, "module_data": club }
                )
              }
            }
            this.showPhotoVideoOption = true
          }

          // Add Organisation
          if (this.userData.modules.organisations) {
            let organisations = this.userData.modules.organisations
            for (let index in organisations) {
              let org = organisations[index]
              let allowedToPost = this.getUserAllowedToPostOrNot(org.org_id)
              if (allowedToPost) {
                this.cvPostAsOptions.push(
                  { name: `${org.org_name} (Organisation)`, id: org.org_id, post_type: 6, "module_data": org }
                )

                if (this.propPostData && this.propPostData.user_module_obj_id === org.org_id) {
                  this.vmPostFormData.post_module_obj_id = this.cvPostAsOptions[this.cvPostAsOptions.length - 1]
                }
                else {
                  this.vmPostFormData.post_module_obj_id = this.cvPostAsOptions[0]
                }
              }
            }
            this.showPhotoVideoOption = true
          }
        }

        // Admin or those users who have have verified by gide to POST Photos and Videos
        if (this.userData.user_role === "USERROLE11111" || this.userData.user_role === "USERROLE11118" || this.userData.user_gide_verified == 1) {
          this.showPhotoVideoOption = true
        }
      }
      catch (err) {
        console.error("Exception occurred at getPostAsList() and Exception:", err.message)
      }
    },
    /**
     * closePostAddModal ()
     */
    closePostAddModal () {
      try {
        this.vmPostVideoLink = false
        this.cvImageError = false
        this.vmPostFormData = Object.assign({}, this.initFormData())
        this.vmPostTags = Object.assign({}, this.initTagData())
        this.vmImageData = Object.assign({}, this.initImageData())
        this.vmVideoData = Object.assign({}, this.initVideoData())
        this.vmImagePreview = []
      }
      catch (err) {
        console.error("Exception occurred at getPostAsList() and Exception:", err.message)
      }
    },
    /**
     * getUserAllowedToPostOrNot
     * @param moduleObjId
     * @returns {boolean}
     */
    getUserAllowedToPostOrNot (moduleObjId) {
      if (this.userData.roles && this.userData.roles[moduleObjId]) {
        if (this.userData.roles[moduleObjId]["udr_name"].toLowerCase() === "admin" || this.userData.roles[moduleObjId]["udr_name"].toLowerCase() === "owner") {
          return true
        }
        else {
          let actions = this.userData.roles[moduleObjId]["actions"]
          for (let index in actions) {
            if (actions[index]["api_name"] === "post_add") {
              return true
            }
          }
          return false
        }
      }
      else {
        return false
      }
    },
    /**
     *  showPostVideoLink
     */
    showPostVideoLink () {
      try {
        this.vmPostVideoLink = true
        this.vmImagePreview = []
      }
      catch (err) {
        console.error("Exception occurred at showPostVideoLink() and Exception:", err.message)
      }
    },
    /**
     * getVideoEmbedeUrl()
     */
    getVideoEmbedeUrl () {
      if (!this.vmVideoData.video) {
        return
      }
      try {
        let url = this.vmVideoData.video
        let youtubeUrlRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm
        let videoIdRegex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/

        // check url is whether youtube url or not
        let validUrl = youtubeUrlRegex.test(url)
        if (!validUrl) {
          this.cvVideoError = true
          this.videoErrMsg = this.cvVideoUrlMsg
          this.vmVideoData.embeded = ""
          return false
        }

        let videoIdFound = url.match(videoIdRegex)
        if (!videoIdFound) {
          this.vmVideoData.embeded = ""
          this.cvVideoError = true
          this.videoErrMsg = this.cvVideoUrlValidMsg
          return false
        }
        else {
          this.cvVideoError = false
          this.videoErrMsg = ""
          let videoId = videoIdFound[1]
          this.vmVideoData.embeded = `https://www.youtube.com/embed/${videoId}`
        }
      }
      catch (err) {
        console.error("Exception occurred at getVideoEmbedeUrl() and Exception:", err.message)
      }
    }
  },
  watch: {
    "vmPostFormData.post_module_obj_id" () {
      if (this.vmPostFormData.post_module_obj_id) {
        let moduleData = this.vmPostFormData.post_module_obj_id
        if (moduleData.module_data && Object.keys(moduleData.module_data).length > 0) {
          if (moduleData.post_type === 1) {
            this.propPostUserData = {
              "user_name": moduleData.module_data.org_name,
              "user_image": {}
            }
            this.propPostUserData["user_image"]["profile_pic"] = moduleData.module_data.profile_pic ? moduleData.module_data.profile_pic : null
          }
          else if (moduleData.post_type === 3) {
            this.propPostUserData = {
              "user_name": moduleData.module_data.club_name,
              "user_image": {}
            }
            this.propPostUserData["user_image"]["profile_pic"] = moduleData.module_data.profile_pic ? moduleData.module_data.profile_pic : null
          }
        }
        else {
          this.propPostUserData = {}
        }
      }
    },
    vmPostAddModal () {
      if (!this.vmPostAddModal) {
        this.closePostAddModal()
      }
    },
    "vmVideoData.video" () {
      this.getVideoEmbedeUrl()
    }
  }
}
</script>

<style lang="scss" scoped >
.dropZone {
  opacity: 0;
  z-index: 0;
  position: absolute;
  transform: scale(0);
  top: 0;
  left: 0;
  height: 0;
  width: 0;
}
.dropZone-over {
  width: 100%;
  height: 100%;
  transform: scale(1);
  z-index: 9 !important;
}
.stacking {
  z-index: 10;
}
#photo-grid img {
  background: #FFFFFF!important;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.deleteImgIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  z-index: 9999;
  position: absolute;
  margin-left: -22px;
  border: none;
  cursor: pointer;
  margin-top: 1px;
  i {
    position: relative;
    left: 0px;
    top: -2px;
    font-size: 13px !important;
  }
}
.imageContainer {
  height: 147px;
  width: 147px;
  margin-right: 10px;
  margin-top: 7px;
}
</style>
