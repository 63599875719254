/*eslint-disable */
//https://firebase.google.com/docs/cloud-messaging/js/client
//https://github.com/firebase/quickstart-js/tree/master/messaging

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import request from '../../Utils/curl'

let PushTokenHandler = {
  /**
   * fcm_push_token_get
   *
   */
  async fcm_push_token_get(self, counter = 0) {
    let fcm_push_token = null;
    let device_id = "web";
    
    //For Web Push Notification
    try {
      // https://pub.dev/packages/flutter_inappwebview/versions/5.1.0+3
      if(window.flutter_inappwebview){
        fcm_push_token = window.flutter_inappwebview.callHandler('getFCMPushToken')
        .then(function(currentToken) {
          if(navigator.userAgent.toLowerCase().includes("iphone")
          || navigator.userAgent.toLowerCase().includes("ipod")
          || navigator.userAgent.toLowerCase().includes("ipad")
          ){
            device_id = "ios";
          }
          else if(navigator.userAgent.toLowerCase().includes("android")){
            device_id = "android";
          }            
          else {
            device_id = "web";
          }

          // console.log(device_id, "XXXX navigator.userAgent", navigator.userAgent)

          PushTokenHandler.fcm_push_token_update(self, device_id, currentToken);

          return currentToken;
        });
      }
      else{
        var firebaseConfig = {
          apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
          authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
          projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
          messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
          appId: process.env.VUE_APP_FIREBASE_APPID,
        };
      
        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
  
        // Get registration token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        const messaging = getMessaging(app);
        fcm_push_token = await getToken(messaging, { vapidKey: process.env.VUE_APP_FIREBASE_CLOUDMESSAGING_WEBPUSHCERT_KVPAIR })
        .then((currentToken) => {
          if (currentToken) {
            console.log('Notification permission granted', currentToken);
            
            device_id = "web";
            PushTokenHandler.fcm_push_token_update(self, device_id, currentToken);
  
            return currentToken;
          } 
          else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });        
        
  
        {console.log("FCM fcm_push_token =", fcm_push_token);}  
  
        onMessage(messaging, (payload) => {
          console.log("Message received in foregorund. ", JSON.stringify(payload));
        });
      }
    }
    catch (error) {
      console.error("Firebase error", error);
    }

    return fcm_push_token;
  },
  /**
   * fcm_push_token_update
   *
   */
  async fcm_push_token_update(self, device_id, new_fcm_push_token) {
    let current_fcm_push_token = await self.$store.getters['User/getFcmPushToken']
    
    //Save the Push token in Cache if Not same
    { console.log("push_token_update: Old FCMToken=", current_fcm_push_token, "New FCM_Token=", new_fcm_push_token, "Device=", device_id); }
    
    if (!new_fcm_push_token 
      || (current_fcm_push_token && current_fcm_push_token == new_fcm_push_token)
    ) {
      //Nothing to update 
      //Or Saved token and new token matches then dont update the backend
      return;
    }

    //Prep to update
    await self.$store.dispatch('User/setFcmPushTokenAction', new_fcm_push_token)

    console.log("push_token_update: fcm_push_token: ", current_fcm_push_token);
    var post_data = new FormData();
    post_data.append('fcm_push_token', new_fcm_push_token);
    post_data.append('device_id', device_id);
    request.curl(this, "user_fcm_push_token_update", post_data);
  },

}

export {
  PushTokenHandler
}