<template>
  <iq-card class="side-iq-card">
    <template v-slot:headerTitle>
      <h4 class="card-title">
        Clubs
      </h4>
    </template>
    <template v-slot:headerAction>
      <p v-if="checkClubCreateAccess()" class="m-0">
        <a @click="goToClubCreate()" style="cursor: pointer;">Create </a>
      </p>
    </template>
    <template v-slot:body>
      <hr class="mt-0"/>
      <ul class="media-story m-0 p-0" v-if="clubListObj && Object.keys(clubListObj).length">
        <li v-for="(club,index) in clubListObj" :key="index" class="d-flex align-items-center mt-3 btn p-0" @click="goToClubView(club.club_id)">
          <img v-if="clubImgObj && clubImgObj[club.club_id] && clubImgObj[club.club_id]['profile_pic']" :src="clubImgObj[club.club_id]['profile_pic']['image_thumbnail']" class="avatar-50 rounded-circle" alt="Club">
          <div v-else class="rounded-circle"  style="border: 2px solid rgba(0, 0, 0, 0.1);padding: 2px;">
            <b-avatar variant="primary" class="b-avatar badge-primary rounded-circle avatar-50" :text="getFirstLetterOfAString(club.club_name)" >
            </b-avatar>
          </div>
          <div class="ml-2 d-flex flex-fill justify-content-between leftAlign ListPage">
            <h5 class="homeHeading">{{ club.club_name }}
              <br>
              <span class="clubBySpan">by {{club.org_name ? club.org_name : club.user_name}}</span>
            </h5>
            <button style="all:unset; font-weight: 500; cursor: pointer;" v-if="clubFollowObj[club.club_id]" @click="followClub($event, club.club_id, clubFollowObj[club.club_id].followed_by_curr_user)">
              <span v-if="clubFollowObj[club.club_id].followed_by_curr_user===0" class="text-primary">Follow</span>
              <span v-else class="text-secondary">Unfollow</span>
            </button>
          </div>
        </li>
      </ul>
      <div v-else >
        No clubs
      </div>
      <b-btn class="d-block mt-3 border-light iq-bg-dark" style="width: 100%;" @click="goToClubList()">
        <i class="ri-add-line"></i> See All
      </b-btn>
    </template>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </iq-card>
</template>
<style lang="scss" scoped>
.clubBySpan {
  font-size: 11px;
  color: #6c757d;
  top: -6px;
  position: relative;
}
</style>
<script>

import { Clubs } from "../../../../FackApi/api/club"
import { Follows } from "../../../../FackApi/api/follow"
import ApiResponse from "../../../../Utils/apiResponse.js"

export default {
  name: "ClubHomeList",
  mounted () {
    this.loadRequiredData()
  },
  props: {
    propModuleObjId: {
      type: String
    },
    propModuleType: {
      type: String
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  components: {
  },
  data () {
    return {
      clubListObj: [],
      clubImgObj: {},
      clubFollowObj: {},
      vmFollowData: Object.assign({}, this.initFollowData()),
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Club Follow Response"
    }
  },
  methods: {
    /**
     * initFollowData
     */
    initFollowData () {
      return {
        module_id: "",
        module_name: "",
        follow_status: 0
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      this.clubList()
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * goToEditClub
     */
    goToClubList () {
      this.$router.push("/clubs")
    },
    /**
     * goToClubView
     */
    goToClubView (clubId) {
      this.$router.push("/club/" + clubId)
    },
    /**
     * userRoleAllowedToPostOrNot
     */
    userRoleAllowedToPostOrNot () {
      if (this.userData && this.userData.user_role) {
        return this.userData.user_role === "USERROLE11111" || this.userData.user_role === "USERROLE11118" || this.userData.user_role === "USERROLE11115"
      }
    },
    /**
     * checkClubCreateAccess
     */
    checkClubCreateAccess () {
      if (this.userData.user_role === "USERROLE11111" || this.userData.user_role === "USERROLE11118") {
        return true
      }
      else if (this.propModuleObjId && this.userData && this.userData.roles && this.userData.roles[this.propModuleObjId] && this.userData.roles[this.propModuleObjId]["actions"]) {
        if (this.userData.roles[this.propModuleObjId].udr_name && (this.userData.roles[this.propModuleObjId].udr_name.toLowerCase() === "admin" || this.userData.roles[this.propModuleObjId].udr_name.toLowerCase() === "owner")) {
          return true
        }
        let actions = this.userData.roles[this.propModuleObjId]["actions"]
        let orgEditAccess = actions.findIndex(obj => {
          return obj.api_name.toLowerCase() === "club_add"
        })
        if (orgEditAccess > -1) {
          return true
        }
      }
      else {
        return false
      }
      /* else if (!this.propModuleObjId && this.userData.user_role === 'USERROLE11115') {
        return true
      }  */
    },
    /*
   * ClubList
   * */
    async clubList () {
      try {
        let filter = {
          limit: 3
        }
        if (this.propModuleObjId) {
          filter.module_obj_id = this.propModuleObjId
        }
        let clubListResp = await Clubs.clubList(this, "", filter)
        if (clubListResp && clubListResp.resp_status) {
          this.clubListObj = clubListResp.resp_data.data
          this.clubImgObj = clubListResp.club_images
          this.clubFollowObj = clubListResp.club_follow
        }
      }
      catch (err) {
        console.error("Exception occurred at clubList() and Exception:", err.message)
      }
    },
    /**
     * goToClubCreate
     */
    goToClubCreate () {
      let OwnerModuleObjId = null
      let params = this.$route.params

      if (params && Object.keys(params).length) {
        for (const key in params) {
          if (key.includes("_id")) {
            // univ_id, school_id etc
            OwnerModuleObjId = params[key]
            break
          }
        }
      }

      if (!OwnerModuleObjId) {
        OwnerModuleObjId = this.userData.user_id
      }

      this.$router.push(`/club_create/${OwnerModuleObjId}`)
    },
    /**
     *  followclub
     */
    async followClub (event, moduleId, followStatus) {
      event.stopPropagation()
      try {
        this.vmFollowData.module_id = moduleId
        this.vmFollowData.module_name = "CLUB"
        this.vmFollowData.follow_status = followStatus ? 0 : 1
        let followResp = await Follows.followAdd(this, this.vmFollowData)
        await ApiResponse.responseMessageDisplay(this, followResp)
        if (followResp && !followResp.resp_status) {
          return
        }
        else {
          this.clubFollowObj[moduleId].followed_by_curr_user = this.vmFollowData.follow_status
          this.vmFollowData = Object.assign({}, this.initFollowData())
        }
      }
      catch (err) {
        console.error("Exception occured at followClub() and Exception", err.message)
      }
    }
  }
}
</script>
