/*eslint-disable */
// import UserContentExcludeModel from '../../Model/UserContentExclude'
import request from '../../Utils/curl'

let UserContentExcludes = {
  /**
   * userContentExcludeList
   */
  async userContentExcludeList (context) {
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "userContentExclude_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at userContentExcludeList() and Exception:',err.message)
    }
  },

  /**
   * userContentExcludeView
   */
  async userContentExcludeView (context, userContentExcludeId) {
    try {      
      let post_data = new FormData();
      post_data.append('ugcx_id', userContentExcludeId);
      return await request.curl(context, "userContentExclude_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at userContentExcludeView() and Exception:",err.message)
    }    
  },

  /**
   * userContentExcludeAdd
   */
  async userContentExcludeAdd (context, userContentExcludeObj) {
    try{
      let post_data = new FormData();
    
      for (let key in userContentExcludeObj) {
        post_data.append(key, userContentExcludeObj[key]);
      }

      return await request.curl(context, "userContentExclude_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at userContentExcludeAdd() and Exception:',err.message)
    }
  },

  /**
   * userContentExcludeEdit
   */
  async userContentExcludeEdit (context, userContentExcludeObj) {
    try{
      let post_data = new FormData();
    
      for (let key in userContentExcludeObj) {
        post_data.append(key, userContentExcludeObj[key]);
      }

      return await request.curl(context, "userContentExclude_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at userContentExcludeEdit() and Exception:',err.message)
    }
  },

  /**
   * userContentExcludeDelete
   */
  async userContentExcludeDelete (context, userContentExcludeId) {
    try{
      let post_data = new FormData();
      
      post_data.append('ugcx_id', userContentExcludeId);

      return await request.curl(context, "userContentExclude_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at userContentExcludeDelete() and Exception:',err.message)
    }
  }
}

export {
  UserContentExcludes
}
