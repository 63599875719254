/*eslint-disable */
// import admin_report_issueModel from '../../Model/admin_report_issue'
import request from '../../Utils/curl'

let adminReportIssue = {
  /**
   * admin_report_issueList
   */
  async admin_report_issueList (context) {
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "admin_report_issue_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at admin_report_issueList() and Exception:',err.message)
    }
  },

  /**
   * admin_report_issueView
   */
  async admin_report_issueView (context, admin_report_issueId) {
    try {
      
      let post_data = new FormData();
      post_data.append('arr_id', admin_report_issueId);
      return await request.curl(context, "admin_report_issue_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at admin_report_issueView() and Exception:",err.message)
    }
    
  },

  /**
   * admin_report_issueAdd
   */
  async admin_report_issueAdd (context, admin_report_issueObj) {
    try{
    let post_data = new FormData();
    
    for (let key in admin_report_issueObj) {
      post_data.append(key, admin_report_issueObj[key]);
    }

    return await request.curl(context, "admin_report_issue_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at admin_report_issueAdd() and Exception:',err.message)
    }
  },

  /**
   * admin_report_issueEdit
   */
  async admin_report_issueEdit (context, admin_report_issueObj) {
    try{
    let post_data = new FormData();
    
    for (let key in admin_report_issueObj) {
      post_data.append(key, admin_report_issueObj[key]);
    }

    return await request.curl(context, "admin_report_issue_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at admin_report_issueEdit() and Exception:',err.message)
    }
  },

  /**
   * admin_report_issueDelete
   */
  async admin_report_issueDelete (context, admin_report_issueId) {
    try{
    let post_data = new FormData();
    
    post_data.append('arr_id', admin_report_issueId);

    return await request.curl(context, "admin_report_issue_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at admin_report_issueDelete() and Exception:',err.message)
    }
  }
}

export {
  adminReportIssue
}
