/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{ cvCardTitle }}
            </h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">
              {{ cvCardSubHeader }}
            </p>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label
                    for="validationcomment">
                    {{cvCommentLabel}}</label>
                  <input
                    v-model="vmadmin_report_issueFormData.comment"
                    type="text"
                    class="form-control"
                    required/>
                </div>
              </div>
              <div class="row spinner_add">
                <div class="col-7">
                <button
                  type="button"
                  class="btn btn-primary w-100px ml-2"
                  @click="admin_report_issueAdd()">
                  {{ cvSubmitBtn }}
                </button>
              </div>
                <div class="col-5">
                  <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning">
                    </b-spinner>
                  </button>
                </div>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { adminReportIssue } from "../FackApi/api/adminReportIssue.js"
import ApiResponse from "../Utils/apiResponse.js"

export default {
  name: "ReportIssue",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propModuleType: {
      type: String,
      default: ""
    },
    propModuleId: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      cvCardTitle: "Report an issue",
      cvCardSubHeader: "",
      cvSubmitBtn: "Submit",
      cvModuleObjIdLabel: "module obj id",
      cvCommentLabel: "Comment",
      cvModuleTypeLabel: "module type",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Admin Report Issue",
      vmadmin_report_issueFormData: Object.assign({}, this.initFormData())
    }
  },
  mounted () {
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "module_obj_id": this.propModuleId,
        "comment": "",
        "module_type": this.propModuleType
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmadmin_report_issueFormData) {
          if (!this.vmadmin_report_issueFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * admin_report_issueAdd
     */
    async admin_report_issueAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let adminReportIssueAddResp = await adminReportIssue.admin_report_issueAdd(this, this.vmadmin_report_issueFormData)
        await ApiResponse.responseMessageDisplay(this, adminReportIssueAddResp)
        if (adminReportIssueAddResp && !adminReportIssueAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseadminReportIssueAddModal", this.vmadmin_report_issueFormData)
        }
        this.vmadmin_report_issueFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at admin_report_issueAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
